.section-sub-title {
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  color: #686a6f;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 12px;
  letter-spacing: 0.10em;
}
.section-sub-title::after {
  content: "";
  background-color: #47b3e4;
  width: 20px;
  height: 2px;
}
.p-content{
    font-size: 16px;
}
