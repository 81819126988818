.services-page-head-bg {
  width: 100%;
  height: 380px;
  background-image: url("/public/services-page-bg.jpg");
  background-size: cover;
  background-attachment: fixed !important;
  animation: shrink 10s infinite alternate;
}
.service-bg-container {
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;
}
.service-title {
  font-size: 48px;
  color: white;
  font-weight: 600;
}

.services-page-card-section {
  padding-top: 70px;
  padding-bottom: 100px;
}
.services-page-card-section::after {
  top: -150px;
  content: "";
  right: 0px;
  z-index: -1;
  width: 1300px;
  height: 900px;
  position: absolute;
  background-size: auto;
  background-repeat: no-repeat;

  animation: scale 3s linear infinite alternate-reverse;
  background-image: url("/public/why-counter-overly-4.png");
}
.services-card-section-title {
  font-size: 32px;
  color: #0e121d;
  font-weight: 600;
  margin-bottom: 40px;
}
.services-page-card {
  background-color: white;
  margin-bottom: 40px;
  transition: ease-in-out 0.6s;
}
.services-page-card:hover {
  /* transform: translateY(-10px); */
}
.services-page-card .service-img {
  transition: ease-in-out 1.2s;
}
.services-page-card:hover .service-img {
  transform: scale(1.2);
}

.services-page-card-img {
  width: 100%;
  overflow: hidden;
  clip-path: polygon(100% 0, 100% 75%, 92% 100%, 0 100%, 0 0);
  position: relative;
}
.services-page-card-img img {
  width: 100%;
  height: auto;
}
.services-page-card-img::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgb(0, 15, 49);
  background: linear-gradient(
    0deg,
    rgba(0, 15, 49, 1) 0%,
    rgba(0, 15, 49, 0) 100%
  );
}
.services-page-card-content {
  padding: 20px;
  border: 1px solid #e4e4e4;
  margin-bottom: 12px;
}
.services-page-card-content h3 {
  font-size: 18px;
  font-weight: 600;
  color: #0e121d;
  margin-bottom: 16px;
}
.services-page-card-content p {
  font-size: 16px;
  font-weight: 400;
  color: #444444;
}
.services-page-rm-btn {
  color: #0e121d;
  display: flex;
  font-size: 16px;
  font-weight: 500;
  align-items: center;
}
.services-page-card:hover .services-page-rm-btn {
  color: #47b3e4;
}

.services-page-rm-btn::after {
  content: "";
  background-color: #dbdbdb;
  width: 50px;
  height: 1px;
  margin-left: 6px;
  transition: 0.6s;
}
.services-page-card:hover .services-page-rm-btn::after {
  background-color: #47b3e4;
  width: 0px;
}
.services-page-rm-btn::before {
  content: "";
  background-color: #47b3e4;
  width: 0px;
  height: 1px;

  transition: 0.6s;
}
.services-page-card:hover .services-page-rm-btn::before {
  width: 50px;
  margin-left: 6px;
}
.slick-dots {
  text-align: left !important;
  position: relative !important;
  margin-top: 24px !important;
}
.slick-dots li {
  margin: 0px !important;
  margin-right: 16px !important;
  width: 50px !important;
  height: 30px !important;
}
.slick-dots div {
  color: black !important;
}
.slick-dots div::before {
  content: "";
  width: 100%;
  height: 2px;
  background-color: black;
  display: block;
  position: relative;
  top: -10px;
}
.slick-dots .slick-active div::before {
  content: "";
  width: 100%;
  height: 2px;
  background-color: #47b3e4;
  display: block;
}

.slick-prev {
  background: url(/public/arrow-left-b.png) !important;
  z-index: 99;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  width: 40px;
}
.slick-prev::before {
  display: none;
}
.slick-next {
  background: url(/public/arrow-right-b.png) !important;
  z-index: 99;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  width: 40px;
}
.slick-next::before {
  display: none;
}
.slick-disabled {
  opacity: 0.35;
}
.slick-track {
  display: flex;
}
.slick-track .slick-slide {
  margin-right: 24px;
}

@media (width <992px) {
  .slick-next,
  .slick-prev {
    display: none !important;
  }
}
@media (width <450px) {
  .slick-dots li {
    margin: 0px !important;
    margin-right: 16px !important;
    width: 30px !important;
    height: 30px !important;
  }
}
