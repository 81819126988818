.reso-title-container {
  padding-top: 120px;
  padding-bottom: 30px;
  /* text-align: center; */
}
.reso-title-container h1 {
  font-size: 32px;
  color: #0e121d;
  font-weight: 600;
}
.reso-item-bg-container {
  padding-bottom: 70px;
}
.reso-item-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
}
.reso-item {
  position: relative;
  overflow: hidden;
  transition: 1s;
}
.reso-item::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(217, 225, 231, 0) 100%
  );
}
.reso-item-img img {
  transition: 1s;
}
.reso-item:hover .reso-item-img img {
  transform: scale(1.15);
}
.reso-item-content {
  position: absolute;
  height: fit-content;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 20px;
  padding-bottom: 40px;
  z-index: 1;
}
.reso-item-title {
  font-size: 24px;
  font-weight: 600;
  color: white;
  margin-bottom: 16px;
}
.reso-rm-btn {
  display: flex;
  align-items: center;
  gap: 8px;
}
.reso-rm-btn p {
  margin: 0;
}
.reso-rm-btn img {
  height: 12px;
  width: auto;
  transition: 0.6s;
}
.reso-rm-btn:hover.reso-rm-btn img {
  transform: rotate(-45deg);
}

@media (width>991px) {
  .reso-item-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
}
@media (width>1200px) {
    .reso-item-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;
    }
  }
.reso-item-img img {
  width: 100%;
  height: auto;
}
