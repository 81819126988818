.showcase .showcase-text {
    padding: 3rem;
  }
  .showcase .cloud-img {
    min-height: 30rem;
    background-size: cover;
    background-image: url('http://www.canvendor.com/assets/images/services/Pros-of-Cloud-Technology-In-The-Workplace-.jpg');
  }
  .showcase .cloud-img2 {
    min-height: 30rem;
    background-size: cover;
    background-image: url('http://www.canvendor.com/assets/images/services/cloud-1.png');
  }
  @media (min-width: 768px) {
    .showcase .showcase-text {
      padding: 7rem;
    }
  }
  