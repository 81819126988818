.contact-form-section{
    background: url(/public/contact-us-bg.jpg);
    background-position: center;
    background-size: cover;
    padding-top: 200px;
    padding-bottom: 100px;
    margin-top: -50px;

}
.contact-us-title{
    font-size: 28px;
    color: white;
    font-weight: 700;
}
.contact-tagline{
    margin-bottom: 48px;
    color: white;
}
.contact-form-content{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
}
@media(width < 576px){
    .contact-form-content{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 40px;
    }
}
.contact-input-container{
display: flex;
flex-direction: column;
position: relative;

}
.contact-label{
    font-size: 13px;
    color: white;
}
.contact-label span{
    font-size: 13px;
    color: white;
}
.contact-form-content .contact-input{
padding: 0;
color: white;
border: none ;
outline: none !important;
border-bottom: 1px solid white;
background: none;
height: 36px;
font-size: 16px;
}
.contact-checkbox{
    display: flex;
    align-items: center;
    margin-top: 24px;
   align-items: center;
   gap: 12px;
}
.contact-checkbox input{
    width: 16px;
    height: 16px;
    border-radius: 0px !important;
    
}
.contact-checkbox label{
    color: white;
    font-size: 14px;
}
.contact-checkbox label a{
    color: white;
    text-decoration: underline;
    font-size: 14px;
}
.contact-textarea-container{
    display: flex;
   
    flex-direction: column;
}
.contact-textarea-content{
    width: 100%;
}
.contact-textarea{
    border-radius: 0px;
    border: 1px solid white;
    outline: none;
    background: none;
    color: white;
    font-size: 16px;
    width: 100%;
    min-height: 200px;
    max-height: 300px;
    padding: 20px;
}
.contact-textarea-content p{
    color: white;
    font-size: 14px;
    margin-top: 16px;
}
.contact-btn{
    height: 42px;
    background-color: #47B3E4;
    border-radius: 40px;
    font-size: 14px;
    color: #1C1F42;
    font-weight: 600;
    padding: 0px 20px;
}
.contact-btn:hover{
    background-color: #47B3E4 !important;
    opacity: 0.8;
}



