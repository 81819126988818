.indus-page-title-container {
  height: 500px;
}
.manu-indus-bg-img {
  background: linear-gradient(
      0deg,
      rgba(0, 15, 49, 1) 0%,
      rgba(0, 15, 49, 0.3) 100%
    ),
    url("/public/indus-manu-bg.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}
.auto-indus-bg-img {
  background: linear-gradient(
      0deg,
      rgba(0, 15, 49, 1) 0%,
      rgba(0, 15, 49, 0.3) 100%
    ),
    url("./automotiviebanner.png");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}
.bank-indus-bg-img {
  background: linear-gradient(
      0deg,
      rgba(0, 15, 49, 1) 0%,
      rgba(0, 15, 49, 0.3) 100%
    ),
    url("/public/indus-bank-bg.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}
.commu-indus-bg-img {
  background: linear-gradient(
      0deg,
      rgba(0, 15, 49, 1) 0%,
      rgba(0, 15, 49, 0.3) 100%
    ),
    url("/public/indus-commu-bg.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}
.health-indus-bg-img {
  background: linear-gradient(
      0deg,
      rgba(0, 15, 49, 1) 0%,
      rgba(0, 15, 49, 0.3) 100%
    ),
    url("/public/indus-health-bg.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}

.indus-title-content-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
.indus-page-title {
  font-size: 32px;
  font-weight: 600;
  color: white;
}
@media (width>575px) {
  .indus-page-title {
    font-size: 48px;
  }
}
.indus-page-tagline {
  font-size: 18px;
  font-weight: 400;
  color: white;
}
.indus-content-section {
  padding-top: 70px;
  padding-bottom: 70px;
}
.indus-content-title {
  font-size: 32px;
  color: #0e121d;
  font-weight: 600;
  margin-bottom: 16px;
}
.indus-our-services-section {
  padding-top: 30px;
  padding-bottom: 70px;
}
.indus-service-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 24px;
}
@media (width>767px) {
  .indus-service-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
  }
}
@media (width>991px) {
  .indus-service-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
  }
}
.indus-service-item {
  padding: 24px;
  padding-top: 32px;
  background: linear-gradient(
    38deg,
    rgba(31, 33, 72, 1) 45%,
    rgba(69, 171, 220, 1) 100%
  );
  border-radius: 18px;
}
.indus-service-item-title {
  font-size: 24px;
  font-weight: 600;
  color: white;
  margin-bottom: 16px;
}
.indus-service-item-para {
  font-size: 16px;
  font-weight: 400;
  color: white;
}
