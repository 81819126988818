.insights-section-container {
  /* background: url(/public/contact-us-bg.jpg);
background-size: cover;
background-position: center; */
  padding: 70px 0px;
  /* background-color: #f1f3f6; */
  position: relative;
}
.insights-section-container::after {
  top: -600px;
  content: "";
  right: 0px;
  z-index: -1;
  width: 1200px;
  height: 900px;
  position: absolute;
  background-size: auto;
  background-repeat: no-repeat;

  animation: scale 3s linear infinite alternate-reverse;
  background-image: url("/public/why-counter-overly-4.png");
}
@media (width<992px) {
  .insights-section-container::after {
    display: none;
  }
}
.insights-title {
  font-size: 32px;
  font-weight: 600;
  color: #0e121d;
}
.insights-tagline {
  font-size: 16px;
  color: white;
  margin-bottom: 50px;
}
.insights-featured-img-container {
  position: relative;
}
.insights-featured-content {
  position: absolute;
  bottom: 20px;
  background-color: white;
  padding: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  clip-path: polygon(100% 0, 100% 70%, 93% 100%, 0 100%, 0 0);
}
.insights-featured-item {
  overflow: hidden;
}

.insights-featured-item img {
  transition: ease-in-out 1s;
}

.insights-featured-item:hover img {
  transform: scale(1.2);
}
.insights-content-grid {
  padding-left: 30px;
}
@media (width<1200px) {
  .insights-content-grid {
    padding-left: 12px;
  }
}
.insights-item {
  /* background-color: white; */
  display: flex;
  gap: 20px;
  margin-bottom: 24px;
  transition: ease-in-out 0.3s;
}
/* .insights-item:hover{
  transform: translateY(-5px);
} */
.insights-img-container {
  width: 200px;
  overflow: hidden;
  display: flex;
  align-items: center;
  /* border-top: 1px solid rgb(0 15 49); */
  /* border-radius: 12px; */
}
.insights-img {
  transition: 0.2s;
  width: 100%;
  height: auto;
}
.insights-img {
  transition: ease-in-out 0.8s;
}
.insights-item:hover .insights-img {
  transform: scale(1.1);
}

.insights-content {
  /* border-bottom: 1px solid rgb(0 15 49); */
  width: 100%;
  /* padding-top: 20px; */
}
.insights-category {
  font-size: 13px;
  font-weight: 500;
  color: #47b3e4;
}
.insights-item-title {
  font-size: 20px;
  font-weight: 600;
  color: #444444;
  margin-bottom: 12px;
}
.insights-know-btn {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  transition: 0.6s;
  color: #0e121d;
  display: flex;
  align-items: center;
}
.insights-know-btn img {
  width: 8px;
  height: auto;
  transition: 0.2s;
}
.insights-know-btn:hover {
  color: #47b3e4;
}

.insights-know-btn::after {
  content: "";
  background-color: #dbdbdb;
  width: 100px;
  height: 2px;
  margin-left: 6px;
  transition: 0.6s;
}
.insights-featured-item:hover .insights-know-btn::after {
  background-color: #47b3e4;
  width: 0px;
}
.insights-know-btn::before {
  content: "";
  background-color: #47b3e4;
  width: 0px;
  height: 2px;
  
  transition: 0.6s;
}
.insights-featured-item:hover .insights-know-btn::before {
  width: 100px;
  margin-left: 6px;
}


.insights-item:hover .insights-know-btn::after {
  background-color: #47b3e4;
  width: 0px;
}
.insights-item:hover .insights-know-btn::before {
  width: 100px;
  margin-left: 6px;
}
