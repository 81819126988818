#footer {
  background:#D9E1E7 !important;
}
.footer-top h4 {
  color: #0e121d !important;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 12px;
}
.footer-top .footer-links li a {
  color: #0e121d !important;
  font-size: 14px;
  font-weight: 400;
  display: flex !important;
  align-items: center;
}
.footer-top .footer-links li a:hover {
  color: #47b3e4 !important;
}
.footer-top .footer-links li{
    display: flex;
    align-items: center;
    transition: all 0.2s;
}
.footer-top .footer-links li::before{
    content: '';
    width: 0px;
    height: 2px;
    background-color: #47b3e4;
    transition: all 0.2s;

}
.footer-top .footer-links li:hover:before{
    content: '';
    width: 12px;
    height: 2px;
    background-color: #47b3e4;

}
.newsletter-form{

}
.newsletter-title{
    font-size: 24px;
    color: #0e121d !important;
    font-weight: 600;
}
.newsletter-input{
    border: none;
    background: none;
    border-bottom: 1px solid #0e121d;
    padding: 12px 0px;
    width: 100%;
    outline: none;
}
.newsletter-btn{
    padding:12px 16px !important;
    background-color: #0e121d;
}
.footer-bottom{
    background:none !important ;
    border-top: 1px solid #c4c4c4;
    color: #0e121d;
}
.copyright {
    color: #0e121d;
}


