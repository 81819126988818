.contact-card-bg {
  background: linear-gradient(
    45deg,
    rgba(0, 15, 49, 1) 50%,
    rgba(0, 25, 83, 1) 84%,
    rgba(0, 25, 84, 1) 100%
  );
  padding-top: 70px;
  padding-bottom: 70px;
  position: relative;
}
.contact-card-bg-img {
  position: absolute;
  height: 100%;
  right: 0;
  top: 0;
}
.contact-card-container {
  display: flex;
  align-items: center;
  gap: 40px;
}

.contact-card-content {
  color: white;
}

.contact-card-content h3 {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 12px;
}
.contact-card-content p {
  margin-bottom: 0px;
}
.contact-card-img {
  width: 60px;
  height: 60px;
}
.contact-card-img img {
  width: 100%;
  height: auto;
}
.contact-card-btn {
  background-color: #47b3e4 !important;
}

@media (width < 1200px) {
  .contact-card-container {
    flex-direction: column;
    gap: 24px;
  }
  .contact-card-content {
    text-align: center;
  }
}
