.scroll-to-top-btn {
  position: fixed;
  bottom: 30px;
  right: 30px;
  padding: 10px 20px;
  font-size: 20px;
  border-radius: 0px;
  border: none;
  background-color: rgb(31, 33, 72);
  color: #fff;
  cursor: pointer;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  transition: ease-in-out 0.6s;
}
