.contact-page-bg {
  position: relative;
}
.contact-bg-map {
  position: absolute;
  right: 0px;
  top: 780px;
  width: 50%;
  height: auto;
  z-index: -1;
  opacity: 0.3;
}
.contact-page-container {
  padding-top: 110px;
  padding-bottom: 70px;
  display: flex;
}

.contact-page-title {
  font-size: 48px;
  color: #0e121d;
  font-weight: 600;
}
.contact-page-title span {
  position: relative;
}
.contact-page-title span::after {
  content: "";
  position: absolute;
  bottom: 16px;
  z-index: -1;
  left: 0;
  height: 12px;
  width: 100%;
  background-color: #47b3e4;
}
.contact-page-tagline {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 24px;
}
#contact-form input {
  /* background-color: #f5f5f3; */
  outline: none;
  border: 1px solid #dbdbdb;
  float: left;
  height: 50px;
  margin-bottom: 20px;
  padding-left: 20px;
  padding-right: 30px;
  width: 100%;
}
#contact-form input:focus{
    border: 1px solid #0e121d;
}
#contact-form textarea {
  /* background-color: #f5f5f3; */
  border: 1px solid #dbdbdb;
  height: 130px;
  margin-bottom: 16px;
  padding-left: 20px;
  padding-right: 30px;
  padding-top: 20px;
  resize: none;
  width: 100%;
}
.disclaimer-privacy {
  padding: 0px;
  border: none;
}
.disclaimer-privacy h2 {
  font-size: 10px;
  font-weight: 700;
  margin-bottom: 8px;
}
.disclaimer-privacy p {
  font-size: 9px;
  font-weight: 400;
}
.contact-mail-btn {
  width: 100%;
  height: 50px;
  padding: 0px;
  transition:1s;
  position: relative;
  border: none;
}
.contact-mail-btn:hover {
  background-color: rgb(31, 33, 72);
}
.contact-mail-btn::after {
  content: "";
  position: absolute;
  height: 50px;
  width: 0%;
  right:0;
  top: 0;
  transition: 1s;
}
.contact-mail-btn::before {
  content: "";
  position: absolute;
  height: 50px;
  left: 0;
  top: 0;
  width: 0%;
  transition:1s;
}
.contact-mail-btn:hover:after {
  content: "";
  width: 50%;
  background-color: #47b2e45f;
  /* z-index: -1; */
}
.contact-mail-btn:hover:before {
  content: "";
  width: 50%;
  background-color: #47b2e45f;
  /* z-index: -1; */
}
.location-items-container {
  padding-top: 54px;
}
@media (width>991px) {
  .contact-form-container {
    position: sticky;
    top: 110px;
    height: fit-content;
  }
  .location-items-container {
    padding-left: 40px;
  }
  .name-email {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
  .contact-bg-map {
    position: absolute;
    right: 0px;
    top: 100px;
    width: 30%;
    height: auto;
    z-index: -1;
    opacity: 0.3;
  }
}

.locations {
  font-size: 36px;
  color: #0e121d;
  font-weight: 600;
  margin-bottom: 40px;
}

.contact-location-item {
  background-color: #f4f7fb;
  padding: 20px;
  padding-top: 40px;
  margin-bottom: 60px;
  position: relative;
}
.contact-location-continent {
  font-size: 18px;
  background-color: rgb(31, 33, 72);
  width: fit-content;
  padding: 8px 20px;
  text-transform: uppercase;
  color: white;
  position: absolute;
  top: -20px;
}
.country-container {
  margin-bottom: 40px;
}

.contact-location-country {
  font-size: 24px;
  color: #0e121d;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 24px;
  text-decoration: underline;
}
.contact-location-city {
  font-size: 20px;
  color: #0e121d;
  font-weight: 600;
  margin-bottom: 12px;
}
.contact-location-address {
  border-left: 4px solid #47b3e4;
  padding-left: 10px;
}
.location-direction {
  display: flex;
  /* align-items: center; */
  gap: 8px;
  color:#47b3e4 !important;
}
.location-direction span {
  width: 18px;
  height: 18px;
  display: block;
}
.location-direction img {
  width: 100%;
  height: auto;
}
