/*--------------------------------------------------------------
# About Section
--------------------------------------------------------------*/
.about h2 {
  /* font-size: 48px; */
  font-weight: 700;
  font-family: var(--font-secondary);
  margin: 30px 0;
}

@media (min-width: 991px) {
  .about h2 {
    max-width: 65%;
    margin: 0 0 80px 0;
  }
}
.wycanimg {
  background-image: url("../home/whycan.png");
}
.about .our-story {
  padding: 40px;
  background-color: #d6d8da;
}

@media (min-width: 991px) {
  .about .our-story {
    padding-right: 35%;
  }
}

.about .our-story h4 {
  text-transform: uppercase;
  font-size: 18px;
  color: #838893;
}

.about .our-story h3 {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 20px;
  color: var(--color-secondary);
}

.about .our-story p:last-child {
  margin-bottom: 0;
}

.about ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.about ul li {
  padding: 5px 0;
  display: flex;
  align-items: center;
}

.about ul i {
  font-size: 20px;
  margin-right: 4px;
  color: var(--color-primary);
}

.about .watch-video i {
  font-size: 32px;
  transition: 0.3s;
  color: var(--color-primary);
}

.about .watch-video a {
  font-weight: 600;
  color: var(--color-secondary);
  margin-left: 8px;
  transition: 0.3s;
}

.about .watch-video:hover a {
  color: var(--color-primary);
}

.about .about-img {
  min-height: 600px;
  background-size: cover;
  background-position: center;
}

@media (min-width: 992px) {
  .about .about-img {
    position: absolute;
    top: 0;
    right: 0;
  }
}
.about-img {
  background-image: url("http://www.canvendor.com/assets/images/about/about1.jpg");
  height: 400px;
  transition: 2s ease-in-out;
  border-radius: 20px;
}
.about-img:hover {
  -ms-transform: scale(1.05); /* IE 9 */
  -webkit-transform: scale(1.05); /* Safari 3-8 */
  transform: scale(1.05);
}
.stats-counter .stats-item {
  background: #fff;
  box-shadow: 0px 0 30px rgba(82, 86, 94, 0.05);
  padding: 30px;
}

.stats-counter .stats-item i {
  font-size: 42px;
  line-height: 0;
  margin-right: 20px;
  color: var(--color-primary);
}

.stats-counter .stats-item span {
  font-size: 36px;
  display: block;
  font-weight: 600;
  color: var(--color-secondary);
}

.stats-counter .stats-item p {
  padding: 0;
  margin: 0;
  font-family: var(--font-primary);
  font-size: 14px;
}
.counter-box {
  display: block;
  background: #f6f6f6;
  padding: 40px 20px 37px;
  text-align: center;
}

.counter-box p {
  margin: 5px 0 0;
  padding: 0;
  color: #909090;
  font-size: 18px;
  font-weight: 500;
}

.counter-box i {
  font-size: 60px;
  margin: 0 0 15px;
  color: #d2d2d2;
}

.counter {
  display: block;
  font-size: 32px;
  font-weight: 700;
  color: #666;
  line-height: 28px;
}

.counter-box.colored {
  background-color: #0e1e36;
}

.counter-box.colored p,
.counter-box.colored i,
.counter-box.colored .counter {
  color: #fff;
}



.about-section-container {
  padding-top: 70px;
  padding-bottom: 70px;
  /* background-color: #f1f3f6; */
}

.about-section-title {
  font-size: 32px;
  width: fit-content;
  font-weight: 600;
  margin-bottom: 16px;
  color: #0e121d;
}
.secondary-text {
  font-size: 16px;
}
.about-section-container {
  position: relative;
  /* overflow: hidden; */
}
.about-section-container::after {
  top: 0px;
  content: "";
  right: 0px;
  z-index: -1;
  width: 1200px;
  height: 900px;
  position: absolute;
  background-size: auto;
  background-repeat: no-repeat;

  animation: scale 3s linear infinite alternate-reverse;
  background-image: url("/public/why-counter-overly-4.png");
}
@media (width<992px) {
  .about-section-container::after {
    display: none;
  }
}
.about-content img {
  width: 100%;
  height: auto;
}

.about-features {
  gap: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.about-rm-btn {
  margin-top: 20px;
}
.about-rm-btn:hover {
  background-color: rgb(31, 33, 72);
}
.about-rm-btn::after {
  content: "";
  position: absolute;
  height: 50px;
  width: 0%;
  right:0;
  top: 0;
  transition: 1s;
}
.about-rm-btn::before {
  content: "";
  position: absolute;
  height: 50px;
  left: 0;
  top: 0;
  width: 0%;
  transition:1s;
}
.about-rm-btn:hover:after {
  content: "";
  width: 50%;
  background-color: #47b2e45f;
  /* z-index: -1; */
}
.about-rm-btn:hover:before {
  content: "";
  width: 50%;
  background-color: #47b2e45f;
  /* z-index: -1; */
}

.about-feature-item {
  padding: 12px 0px;
  transition: all 0.3s;
  border-radius: 12px;
  display: flex;
  align-items: center;
  gap: 12px;
}
/* .about-feature-item:hover {
  transform: scaleZ(1.03)
} */

.about-feature-item img {
  height: 32px;
  width: auto;
  /* margin-bottom: 24px; */
}
.about-feature-item h3 {
  font-size: 18px;
  font-weight: 600;
  margin: 0px;
  color: #0e121d;
}

.our-mission-vision-section {
  padding-top: 40px;
  padding-bottom: 70px;
  overflow: visible;
}
.our-mission-vision {
  padding-top: 50px;
}

.our-mission-vision-brand img {
  width: 100%;
  height: auto;
}

.our-mission-vision-title {
  font-size: 32px;
  font-weight: 600;
  color: #0e121d;
  margin-bottom: 16px;
}

.our-mission-vision p {
  margin: 0px;
}
.our-mission-vision-para {
  font-size: 42px;
  color: #0e121d;
  font-weight: 500;
}
@media (width<1200px) {
  .our-mission-vision-para {
    font-size: 32px;
  }
}
.our-mission-vision-container {
  position: relative;
  z-index: 4;
  background: linear-gradient(
    45deg,
    rgba(0, 15, 49, 1) 50%,
    rgba(0, 25, 83, 1) 84%,
    rgba(0, 25, 84, 1) 100%
  );
  padding: 0px;
  margin-top: 40px;
}
.our-mission-vision-container::before {
  content: "";
  position: absolute;
  display: block;
  background-color: #fff;
  height: 45px;
  width: 45px;
  overflow: hidden;
  z-index: 10;
  left: -23px;
  top: -23px;
  transform: rotate(-45deg);
}
.our-mission-vision-container::after {
  content: "";
  position: absolute;
  display: block;
  background-color: #fff;
  height: 80px;
  width: 80px;
  overflow: hidden;
  z-index: 10;
  bottom: -40px;
  right: -40px;
  transform: rotate(135deg);
}
.mission-vision-item-bg {
  padding: 40px 30px;
}
.mission-vision {
  color: white;
}
.mission-vision h5 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 16px;
}
.mission-vision p {
  font-size: 14px;
  font-weight: 400;
  /* text-align: justify; */
  color: rgb(255 255 255 / 70%);
}
.mission-item {
  border-bottom: 1px solid rgba(211, 211, 211, 0.256);
  margin-bottom: 24px;
}
/* .mission-vision-img{
  display: flex;
  align-items: center;
  justify-content: center;
} */
.mission-vision-img-container {
  padding: 20px;
  width: 100%;
  height: 100%;
}
.mission-vision-img-content {
  width: 100%;
  /* background-color: #3b4e73; */
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 70px 0px;
}
.mission-vision-img-content img {
  width: 60%;
  height: auto;
  transition: all 0.3s;
}
.our-mission-vision-container:hover img {
  transform: scale(1.05);
}

@media (width < 1200px) {
  .our-mission-vision {
    padding-top: 0px;
  }
}

@media (width < 992px) {
  .about-content {
    margin-top: 40px;
  }
}
@media (width < 768px) {
  .about-features {
    grid-template-columns: repeat(1, 1fr);
    gap: 12px;
  }
}
