.why-canvendor-title-bg {
  background-image: url("/public/why-canvendor-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed !important;
  padding-top: 100px;
  padding-bottom: 200px;
}
.why-canvendor-title {
  font-size: 32px;
  font-weight: 600;
  text-align: center;
  color: white;
  /* margin-bottom: 70px; */
}
@media (width < 576px) {
  .why-canvendor-title {
    font-size: 32px;
  }
}
.why-canvendor-section-container {
  overflow: hidden;
  background: linear-gradient(
    45deg,
    rgba(0, 15, 49, 1) 50%,
    rgba(0, 25, 83, 1) 84%,
    rgba(0, 25, 84, 1) 100%
  );
  margin-top: -100px;
  margin-bottom: 70px;
  clip-path: polygon(100% 0, 100% 78%, 97% 100%, 0 100%, 0 0);
  /* padding-bottom: 70px; */
}
@media (width<992px) {
  .why-canvendor-section-container{
    clip-path: polygon(100% 0, 100% 78%, 95% 100%, 0 100%, 0 0);
  }
}
@media (width<768px) {
  .why-canvendor-section-container{
    clip-path: polygon(100% 0, 100% 78%, 93% 100%, 0 100%, 0 0);
  }
}
@media (width<576px) {
  .why-canvendor-section-container{
    width: 90% !important;
  }
}
@media (width<450px) {
  .why-canvendor-section-container{
    clip-path: polygon(100% 0, 100% 78%, 90% 100%, 0 100%, 0 0);
  }
}

.why-canvendor-grid {
  display: flex;
  /* gap: 30px; */
  position: relative;

  width: calc(325px * 8);
  animation: scroll 20s linear infinite;
}
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-325px * 4));
  }
}
.why-canvendor-grid:hover {
  animation-play-state: paused;
}
.why-canvendor-items {
  width: 325px;
}
.why-canvendor-content {
  font-size: 18px;
  color: #7a8595;
  height: 180px;
}
.why-canvendor-count {
  /* background: rgb(31, 33, 72);
  background: linear-gradient(
    38deg,
    rgba(31, 33, 72, 1) 45%,
    rgba(69, 171, 220, 1) 100%
  ); */
  padding: 36px 24px;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 200px;
  text-align: center;
  justify-content: center;
  /* border-bottom: 5px solid #47b3e4; */
}
.why-canvendor-count h4 {
  font-size: 32px;
  font-weight: 700;
  color: white;
}
.why-canvendor-count p {
  font-size: 16px;
  font-weight: 500;
  color: white;
}
/* @media (width < 1200px) {
  .why-canvendor-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .why-canvendor-content {
height: 100px;
  }
}
@media (width < 992px){
    .why-canvendor-content {
        height: 140px;
          }
}
@media (width < 768px){
    .why-canvendor-content {
        height: 190px;
          }
}
@media (width < 576px) {
  .why-canvendor-grid {
    grid-template-columns: repeat(1, 1fr);
  }
  .why-canvendor-content {
    height: fit-content;
      }
} */
