#nav ul li {
  font-size: 60px;
}
#homecon {
  background-image: url(/public/banner-5.jpg);
  background-attachment: fixed !important;
  background-position: center;
  background-size: cover;
  position: relative;
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  animation: shrink 10s infinite alternate;
}
@keyframes shrink {
  0% {
    background-size: 130% 130%;
  }
  100% {
    background-size: 100% 100%;
  }
}
.banner-img {
  position: absolute;
  width: 40%;
  height: auto;
  right: 0%;
}
.home-banner-content {
  display: flex;
  flex-direction: column;
  align-items: start;
  text-align: left;
}

.banner-title {
  color: white;
  font-size: 48px;
  font-weight: 600;
  margin-bottom: 16px;
}
@media (width<1200px) {
  .banner-title {
    font-size: 38px;
  }
}
@media (width<992px) {
  
  .banner-img {
    display: none;
  }
  .banner-title {
    font-size: 42px;
  }
}
.banner-tagline {
  color: white;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 32px;
}
.explore-btn {
  background-color: white !important;
  font-size: 14px;
  height: 48px;
  width: 250px;
  color: #444444;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.8s;
  border-radius: 0px;
}
.explore-btn:hover {
  background-color: white !important;
  color: #47b3e4 !important;
}
.explore-arrow {
  width: 30px;
  height: auto;
  margin-left: -30px;
  opacity: 0;
  transition: 0.5s;
}
.explore-btn:hover .explore-arrow {
  opacity: 1;
  margin-left: 12px;
}
@media (width > 1600px) {
}
