.about-page-title-bg {
  padding-top: 150px;
  padding-bottom: 100px;
}
.about-page-title {
  font-size: 32px;
  color: #0e121d;
  font-weight: 600;
  margin-bottom: 24px;
}
.about-title-img img {
  width: 100%;
  height: auto;
}
@media (width>991px) {
  .about-page-content {
    padding-top: 70px;
  }
}

@media (width>1200px) {
  .about-title-img {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .about-title-img img {
    width: 90%;
    height: auto;
    float: right;
  }
}

.about-mission-bg {
  background: url("/public/about us mission bg.jpg");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  padding: 130px 0px;
}
.about-mission-container {
  background-color: white;
  padding: 100px 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
}
.about-mission-container h1 {
  color: #0e121d;
  font-size: 42px;
  font-weight: 500;
}
.about-lets-talk {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: 1px solid #0e121d;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  transition: 0.8s;
  cursor: pointer;
  color: #0e121d;
}
.about-lets-talk p {
  font-size: 13px;
  text-transform: uppercase;
}
.about-lets-talk h2 {
  font-size: 20px;
}
.arrow-top-b,
.arrow-top-w {
  position: absolute;
  width: 24px;
  height: auto;
  bottom: 24px;
}
.arrow-top-w {
  opacity: 0;
  transition: 0.8s;
}
.arrow-top-b {
  transition: 0.8s;
}
.about-lets-talk:hover {
  background-color: rgb(31, 33, 72);
  color: rgb(31, 33, 72);
}
.about-lets-talk:hover .arrow-top-b {
  opacity: 0;
  bottom: 80px;
}
.about-lets-talk:hover .arrow-top-w {
  opacity: 1;
  bottom: 80px;
}
.about-details-content-section {
  padding: 70px 0px;
}
.about-details-content-img {
  margin-bottom: 40px;
}
@media (width>991px) {
  .about-details-content-img {
    margin-bottom: 0px;
  }
}
.about-details-content-title {
  font-size: 32px;
  color: #0e121d;
  margin-bottom: 24px;
  font-weight: 600;
}
.about-details-content-img img {
  width: 100%;
  height: auto;
}
