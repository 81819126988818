.careers-bg {
  position: relative;
}
.careers-bg::after {
  content: "";
  position: absolute;
  width: 50%;
  height: 50%;
  bottom: 0;
  right: 0;
  z-index: -1;
  opacity: 0.2;
  background: linear-gradient(
    45deg,
    rgba(0, 15, 49, 1) 50%,
    rgba(0, 25, 83, 1) 84%,
    rgba(0, 25, 84, 1) 100%
  );
  filter: blur(100px);
  -webkit-filter: blur(100px);
}
.careers-section-container {
  padding-top: 150px;
  padding-bottom: 100px;
  /* min-height: 100vh; */
}
.careers-page-title {
  font-size: 32px;
  font-weight: 600;
  color: #0e121d;
}
.careers-page-para {
  font-size: 32px;
  font-weight: 600;
  color: #0e121d;
  margin-bottom: 24px;
}
.careers-page-btn-title {
  margin-bottom: 0px;
  font-size: 16px;
  color: #0e121d;
}
.careers-page-btn-content {
  display: flex;
  align-items: center;
  gap: 30px;
  -webkit-box-shadow: 0px 0px 12px -1px rgba(0, 0, 0, 0.18);
  -moz-box-shadow: 0px 0px 12px -1px rgba(0, 0, 0, 0.18);
  box-shadow: 0px 0px 12px -1px rgba(0, 0, 0, 0.18);
  width: fit-content;
  padding: 12px 20px;
  margin-bottom: 40px;
}
.careers-page-btn {
  position: relative;
}
.careers-page-btn:hover {
  background-color: rgb(31, 33, 72);
}
.careers-page-btn::after {
  content: "";
  height: 48px;
  width: 0%;
  position: absolute;
  right: 0;
  top: 0;
  transition: ease-in-out 1s;
}
.careers-page-btn::before {
  content: "";
  height: 48px;
  width: 0%;
  position: absolute;
  left: 0;
  top: 0;
  transition: ease-in-out 1s;
}
.careers-page-btn:hover:after {
  content: "";
  width: 50%;
  
  background-color: #47b2e45f;
  /* z-index: -1; */
}
.careers-page-btn:hover:before {
  content: "";
  width: 50%;
  
  background-color: #47b2e45f;
  /* z-index: -1; */
}

.careers-page-img img {
  width: 100%;
  height: auto;
}

@media (width>991px) {
  .careers-content {
    padding-top: 70px;
  }
}
@media (width>575px) {
  .careers-page-btn-content {
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    gap: 30px;
    -webkit-box-shadow: 0px 0px 12px -1px rgba(0, 0, 0, 0.18);
    -moz-box-shadow: 0px 0px 12px -1px rgba(0, 0, 0, 0.18);
    box-shadow: 0px 0px 12px -1px rgba(0, 0, 0, 0.18);
    width: fit-content;
    padding: 12px 20px;
  }
}
