.our-company {
  padding: 70px 0px;
}
.tp-section-title {
  font-size: 48px;
}
.home-service-title-container {
  display: flex;
  justify-content: space-between;
  align-items: end;
  margin-bottom: 40px;
}
.service-section-title {
  font-weight: 600;
  margin-bottom: 0px;
  color: #0e121d;
}
.home-services-btn {
  background: none;
  color: #0e121d;
  padding: 0px;
  border-bottom: 1px solid #0e121d;
  font-weight: 500;
}
.home-services-btn:hover {
  color: #47b3e4 !important;
  border-bottom: 1px solid #47b3e4;
}

/* carousel  */
.home-service-swiper-container .swiper {
  padding-bottom: 70px;
}
.home-service-swiper-container .swiper-button-prev {
  background-image: url("/public/arrow-left-b.png");
  background-size: contain;
  background-repeat: no-repeat;
  top: 95% !important;
  left: 92% !important;
}
.home-service-swiper-container .swiper-button-prev::after {
  content: none;
}
.home-service-swiper-container .swiper-button-next {
  background-image: url("/public/arrow-right-b.png");
  background-size: contain;
  background-repeat: no-repeat;
  top: 95% !important;
  right: 0% !important;
}
.home-service-swiper-container .swiper-button-next::after {
  content: none;
}

@media (width<1200px) {
  .home-service-swiper-container .swiper-button-prev {
    left: 90% !important;
  }
}
@media (width<992px) {
  .home-service-swiper-container .swiper-button-prev {
    left: 86% !important;
  }
}
@media (width<768px) {
  .home-service-swiper-container .swiper-button-prev {
    left: 80% !important;
  }
}
.service-card img {
  width: 100%;
  height: auto;
}
.service-card {
  position: relative;
  /* border-radius: 12px; */
  overflow: hidden;
  transition: all 0.3s;
}
.home-service-card-text {
  position: absolute;
  bottom: -75%;
  z-index: 10;
  transition: ease-in-out 1.8s;
  padding: 20px;
  text-align: left;
  pointer-events: none;
  height: 100%;
}
.service-card:hover .home-service-card-text {
  bottom: 0%;
  opacity: 1;
  pointer-events: initial;
}
.service-card-title {
  color: white;
  font-size: 24px;
  text-transform: capitalize;
}
.service-card p {
  font-size: 18px;
  text-transform: lowercase;
  color: white;
  opacity: 0;
  transition: 0.4s;
  z-index: 3;
  line-height: 5;
  transition: ease-in-out 1s;
}
.service-card img {
  transition: ease-in-out 1.2s;
}
.service-card:hover img {
  transform: scale(1.2);
}

.service-card:hover p {
  line-height: 1.3;
  opacity: 1;
}

.service-card:hover .service-card-title {
  color: white;
}
.service-card-content::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    360deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  opacity: 1;
  transition: 0.4s;
}
.service-card:hover .service-card-content::after {
  opacity: 1;
}
@media (width<992px) {
  .home-service-card-text {
    bottom: -40%;
    height: 235px;
  }
  .service-card:hover .home-service-card-text {
    bottom: 30%;
  }
}
@media (width<768px) {
  .home-service-card-text {
    bottom: -25%;
    height: 235px;
  }
  .service-card:hover .home-service-card-text {
    bottom: 40%;
  }
}
