.cvtext{
    font-size: 18px;
    padding-left: 20px;
}

.fileup{

padding-top: 15px !important;
}
.form-section {
    border-bottom: 2px solid #f1f1f1;
    padding: 2rem 4rem;
}

 h4 {
    color: #464646;
}
.form-section-header--required:after {
    color: #da0830;
    content: "*";
    font-size: 1.4rem;
    margin-left: 0.5rem;
}
button.button--secondary {
    color: #3973E6;
    border-color: #3973E6;
}
.button--rounded {
    border-radius: 5rem;
    min-width: 15rem;
    float: right;
}
.button--secondary {
    background: none;
    border-color: #365679;
    border-style: solid;
    border-width: 2px;
    color: #365679;
}
@media (min-width: 60em){
.topbar {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10000;
}
}
.topbar {
    background: white;
    box-shadow: 0 2px 3px #0000001a;
    padding: 1rem 0;
}
.oneclick-wrapper {
    max-width: 1170px;
    padding: 0 2rem;
    width: 100%;
}
.flex-jc-center {
    justify-content: center;
}
.flex-row {
    flex-direction: row;
}
.flex {
    display: flex;
}
.flex-ai-center {
    align-items: center;
}
.font--x-bold {
    font-weight: 700;
}
.margin-vertical--s {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}
.flex-jc-space-between {
    justify-content: space-between;
}
@media (max-width: 59.999em){
.topbar-job-details {
    align-items: flex-end;
    text-align: right;
}
}
.topbar-job-details {
    align-items: center;
    display: flex;
    flex-direction: column;
}