.aiml-services-page-head-bg {
  width: 100%;
  height: 380px;
  /* background-image: url("/public/aiml-services-page-bg.jpg");
  background-size: contain;
  background-attachment: fixed !important; */
  /* animation: shrink 10s infinite alternate; */
  background-color: black;
  position: relative;
}
.aiml-img {
  position: absolute;
  right: 0%;
  height: 70%;
  width: auto;
  top: 30%;
  display: none;
}

.cloud-services-page-head-bg {
  width: 100%;
  height: 380px;
  background-color: #0b103a;
  position: relative;
}
.cloud-img {
  position: absolute;
  right: 0%;
  height: 80%;
  width: auto;
  top: 25%;
  display: none;
}
.digital-services-page-head-bg {
  width: 100%;
  height: 380px;
  background-color: #0a121d;
  position: relative;
}
.digital-img {
  position: absolute;
  right: 0%;
  height: 70%;
  width: auto;
  top: 25%;
  display: none;
}
.it-services-page-head-bg {
  width: 100%;
  height: 380px;
  background-color: #0a121d;
  position: relative;
}
.it-img {
  position: absolute;
  right: 0%;
  height: 75%;
  width: auto;
  top: 25%;
  display: none;
}
.enterprise-services-page-head-bg {
  width: 100%;
  height: 380px;
  background-color: #000403;
  position: relative;
}
.enterprise-img {
  position: absolute;
  right: 0%;
  height: 80%;
  width: auto;
  top: 20%;
  display: none;
}

.app-services-page-head-bg {
  width: 100%;
  height: 380px;
  background-color: #000403;
  position: relative;
}
.app-img {
  position: absolute;
  right: 0%;
  height: 70%;
  width: auto;
  top: 25%;
  display: none;
}
.page-direction {
  font-size: 13px;
  color: white;
  font-weight: 500;
  display: flex;
  text-transform: uppercase;
}
.page-direction p {
  padding-left: 12px;
  margin: 0;
}
.page-direction span {
  border-right: 1px solid #47b3e4;
  padding: 0px 12px;
}
.service-inner-page-title {
  font-size: 48px;
  color: white;
  font-weight: 600;
}
.service-content-section {
  padding-top: 70px;
  padding-bottom: 70px;
}
.service-section-subtitle {
  display: flex;
  align-items: center;
  font-size: 16px;
  margin-bottom: 16px;
  font-weight: 500;
  letter-spacing: 0.18em;
  color: #47b3e4;
}
.service-subtitle-before::after {
  background-color: #47b3e4;
  content: "";
  height: 1px;
  margin-right: 15px;
  width: 100px;
}
.service-page-content-title {
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 16px;
  color: #0e121d;
}
.service-offering-section {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}
.service-offering-card {
  background: linear-gradient(
    38deg,
    rgba(31, 33, 72, 1) 45%,
    rgba(69, 171, 220, 1) 100%
  );
  padding: 24px 20px;
}
.service-offering-card :hover .service-offering-card-icon img {
  transform: rotateY(360deg);
}
.service-offering-card-icon {
  width: 70px;
  height: 70px;
  margin-bottom: 24px;
}
.service-offering-card-icon img {
  width: auto;
  height: 100%;
  transition: 0.8s;
}
.service-offering-card-title {
  font-size: 18px;
  font-weight: 600;
  color: white;
  margin-bottom: 16px;
}
.service-offering-card-content {
  font-size: 15px;
  font-weight: 400;
  color: white;
  margin-bottom: 0px;
}
.aiml-our-offerings {
  padding-top: 70px;
  padding-bottom: 200px;
  background: linear-gradient(
      0deg,
      rgba(0, 15, 49, 1) 0%,
      rgba(0, 15, 49, 0) 100%
    ),
    url("/public/aiml-offering-bg.jpg");
  background-size: cover;
  background-position: center;
  background-attachment: fixed !important;
  /* animation: shrink 10s infinite alternate; */
}
.cloud-our-offerings {
  padding-top: 70px;
  padding-bottom: 200px;
  background: linear-gradient(
      0deg,
      rgba(0, 15, 49, 1) 0%,
      rgba(0, 15, 49, 0) 100%
    ),
    url("/public/cloud-offering-bg.jpg");
  background-size: cover;
  background-position: center;
  background-attachment: fixed !important;
}
.digital-our-offerings {
  padding-top: 70px;
  padding-bottom: 200px;
  background: linear-gradient(
      0deg,
      rgba(0, 15, 49, 1) 0%,
      rgba(0, 15, 49, 0) 100%
    ),
    url("/public/digital-offering-bg.jpg");
  background-size: cover;
  background-position: center;
  background-attachment: fixed !important;
}
.it-our-offerings {
  padding-top: 70px;
  padding-bottom: 200px;
  background: linear-gradient(
      0deg,
      rgba(0, 15, 49, 1) 0%,
      rgba(0, 15, 49, 0) 100%
    ),
    url("/public/it-offering-bg.jpg");
  background-size: cover;
  background-position: center;
  background-attachment: fixed !important;
}
.Enterprise-our-offerings {
  padding-top: 70px;
  padding-bottom: 200px;
  background: linear-gradient(
      0deg,
      rgba(0, 15, 49, 1) 0%,
      rgba(0, 15, 49, 0) 100%
    ),
    url("/public/enterprise-offering-bg.jpg");
  background-size: cover;
  background-position: center;
  background-attachment: fixed !important;
}
.app-our-offerings {
  padding-top: 70px;
  padding-bottom: 200px;
  background: linear-gradient(
      0deg,
      rgba(0, 15, 49, 1) 0%,
      rgba(0, 15, 49, 0) 100%
    ),
    url("/public/app-offering-bg.jpg");
  background-size: cover;
  background-position: center;
  background-attachment: fixed !important;
}
.service-page-offering-title {
  color: white;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 32px;
}
.service-page-offering-para {
  color: white;
  font-size: 32px;
  font-weight: 600;
  border-left: 4px solid #47b3e4;

  padding-left: 20px;
}
.service-card-margin {
  margin-top: -100px;
}

@media (width < 1200px) {
  .service-offering-section {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }
}
@media (width > 991px){
  .aiml-img,
  .cloud-img,
  .digital-img,
  .it-img,
  .enterprise-img,
  .app-img {
    display: block;
  }
}
@media (width < 992px) {
  .service-offering-section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
  
}
@media (width < 768px) {
  .service-offering-section {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 24px;
  }
}
@media (width < 576px) {
  .service-offering-section {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 24px;
  }
}
