@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Saira:ital,wght@0,100..900;1,100..900&display=swap");
body {
  max-width: 100%;
  height: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #444444;
  /* font-family: "Poppins", sans-serif; */
  font-family: "Saira", sans-serif;
  
}
.navbar-brand {
  padding: 0px !important;
}
.dropdown ul{
  border-radius: 0px !important;
}
@media (width < 992px) {
  .dropdown-link {
    padding: 10px;
  }
  .navbar-collapse {
    height: calc(100vh - 57px);
    overflow-y: auto;
  }
}
#whycan {
  color: white;
  padding-top: 80px;
  font-weight: bold;
}
#why-con {
  background-image: linear-gradient(
      to right,
      rgba(24, 23, 23, 0.762),
      rgba(205, 193, 201, 0)
    ),
    url("http://www.canvendor.com/assets/images/tcs-lead.jpg");
  height: 100vh;
}
#whyread {
  background-color: #040c1900;
  border-radius: 30px;
  border: 1px solid white;
  color: white;
}
#whyread a {
  color: white;
}
#homecon {
  background: rgb(0, 15, 49);
  background: linear-gradient(
    45deg,
    rgba(0, 15, 49, 1) 50%,
    rgba(0, 25, 83, 1) 84%,
    rgba(0, 25, 84, 1) 100%
  );
  /* background: rgb(31,33,72);
    
  background-image: linear-gradient(to left bottom, #1F2148 36%, #3434a7 100%); */
}
.main-head {
  padding-left: 30px;
}
#Discon {
  font-size: 1.3em;
  line-height: 40px;
  color: #fff;
  margin-bottom: 2.25rem;
  text-align: justify;
  padding-top: 50px;
}
.maintitle {
  color: white;
  word-wrap: break-word;
  font-size: 70px;

  cursor: pointer;
  line-height: 1.5;
  text-align: center;
}
.hover-underline-animation {
  /*  background-image: linear-gradient(27deg, rgba(228,17,101,1) 0%, rgba(138,13,179,1) 61%);*/
  background-image: linear-gradient(
    to right,
    rgba(120, 233, 253, 0.8) 0%,
    rgba(120, 233, 253, 0.4) 0%
  );
  background-repeat: no-repeat;
  background-size: 100% 0.1em;
  background-position: 0 98%;
  transition: background-size 0.25s ease-in;
}

#navbar > ul > li {
  font-size: 40px;
}

a {
  color: #47b2e4;
  text-decoration: none;
}

a:hover {
  color: #73c5eb;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  /* font-family: "Poppins", sans-serif; */
}

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background: #37517e;
}

#preloader:before {
  content: "";
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid #040c19;
  border-top-color: #fff;
  border-bottom-color: #fff;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  -webkit-animation: animate-preloader 1s linear infinite;
  animation: animate-preloader 1s linear infinite;
}

@-webkit-keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.canvas-bubble {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 996;
  background: #47b2e4;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  transition: all 0.4s;
}

.back-to-top i {
  font-size: 24px;
  color: #fff;
  line-height: 0;
}

.back-to-top:hover {
  background: #6bc1e9;
  color: #fff;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

.industries-table {
  vertical-align: inherit;
  width: 100%;
}

.industries-table-row {
  display: flex;
  justify-content: space-between;
}
.industries-table-data {
  background: #37517e;
  color: white !important;
  font-size: 25px;
  border-radius: 5px;
  width: 150%;
  display: flex;
  justify-content: space-evenly;
  align-items: baseline;
  margin-bottom: 15px;
  margin-right: 5px;
}

#header {
  transition: all 0.5s;
  z-index: 997;
  padding: 0px 0;
}

#header .logo {
  font-size: 30px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 2px;
  text-transform: uppercase;
}

#header .logo a {
  color: #fff;
}

#header .logo img {
  max-width: 200px;
  max-height: 60px;
}

.bg-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 10px;
  color: #ffffff;
  display: flex;

  align-items: center;
}

.skills-content {
  display: flex;
}

#user-img {
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

#user-img:hover {
  transform: scale(1.05);
  filter: brightness(120%);
}

.navbar-container .navbar {
  /* background: rgb(0,15,49);
    background: linear-gradient(45deg, rgba(0,15,49,1) 50%, rgba(0,25,83,1) 84%, rgba(0,25,84,1) 100%);
     padding-top: 30px;*/
  background: white !important;
  box-shadow: 0px 4px 37px -15px rgba(0, 0, 0, 0.28);
}
@media (width<992px) {
  .navbar {
    background: white !important;
  }
  .navbar-nav a,
  li {
    color: rgb(14, 14, 71) !important;
  }
  .navbar-brand {
    display: none !important;
  }
}
@media (min-width: 992px) {
  .brand {
    display: none;
  }
  .navbar-brand {
    display: block;
  }
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar li {
  position: relative;
  margin-top: 10px;

  margin-right: 10px;
}

.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 10px;
  font-size: 15px;
  font-weight: 400;
  color: #000000;
  letter-spacing: 0.1em;
  white-space: nowrap;
  transition: 0.3s;
}

.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover > a {
  color: #47b2e4;
}
.dropdown:hover .dropdown-menu {
  display: block;
}
.dropdown-menu {
  font-size: 10px;
  word-wrap: break-word;
}
.navbar .getstarted,
.navbar .getstarted:focus {
  padding: 8px 20px;
  margin-left: 30px;
  border-radius: 50px;
  color: #fff;
  font-size: 14px;
  border: 2px solid #47b2e4;
  font-weight: 600;
}

.navbar .getstarted:hover,
.navbar .getstarted:focus:hover {
  color: #fff;
  background: #31a9e1;
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 14px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
  border-radius: 4px;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  padding: 10px 20px;
  font-size: 14px;
  text-transform: none;
  font-weight: 500;
  color: #0c3c53;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover > a {
  color: #47b2e4;
}

.navbar .dropdown:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover > ul {
    left: -100%;
  }
}
@media (max-width: 900px) {
  .tp-ct-form input {
    width: 100% !important;
  }
  .tp-ct-form {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .section-title-service {
    width: 750px;
  }
  .tp-modify-width {
    width: 100% !important;
  }
  .tp-section-title-sm {
    padding: 20px;
  }
  .tp-section-title-sm-box {
    padding: 20px !important;
  }
  .automotive-content {
    /* margin-top: 10px !important; */
    margin-left: 10px !important;
    width: 400px !important;
    height: 400px !important;
    word-wrap: break-word;
  }
  .section-title-serviceCloud {
    width: 750px;
  }
  .maintitle {
    color: white;
    word-wrap: break-word;
    font-size: 30px;

    cursor: pointer;
    line-height: 1.5;
    text-align: center;
  }
  .clients {
    display: none;
  }
  .location-section {
    display: none;
  }
  .aboutsideimg {
    display: none;
  }
  .about-container {
    padding-left: 0px;
  }
  .about-bg {
    width: 400px;
    position: absolute !important;
    z-index: 2;
    top: 30px !important;
    left: 20px !important;
  }
}
.mobile-nav-toggle {
  color: #fff;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

.mobile-nav-toggle.bi-x {
  color: #fff;
}

@media (max-width: 991px) {
  .our-company .container:after {
    top: -587px;
    content: "";
    left: -564px;
    z-index: -1;
    width: 0px !important;
    height: 1285.06px;
    position: absolute;
    background-size: auto;
    background-repeat: no-repeat;

    animation: scale 3s linear infinite alternate-reverse;
    background-image: url("/public/why-counter-overly-3.png");
  }
  .mobile-nav-toggle {
    display: block;
  }

  .navbar ul {
    display: none;
  }
}

.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(40, 58, 90, 0.9);
  transition: 0.3s;
  z-index: 999;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}

.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  border-radius: 10px;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
}

.navbar-mobile a,
.navbar-mobile a:focus {
  padding: 10px 20px;
  font-size: 15px;
  color: #37517e;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover > a {
  color: #47b2e4;
}

.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
  margin: 15px;
  color: #37517e;
}

.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
  min-width: 200px;
}

.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover > a {
  color: #47b2e4;
}

.navbar-mobile .dropdown > .dropdown-active {
  display: block;
}

.banner-container {
  position: relative;
}

.banner-content {
  position: absolute;
  top: 200px;
}

#hero {
  width: 100%;
  height: 80vh;
  background: #37517e;
}

#hero .container {
  padding-top: 72px;
}

#hero h1 {
  margin: 0 0 10px 0;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  color: #fff;
}

#hero h2 {
  color: rgba(255, 255, 255, 0.6);
  margin-bottom: 50px;
  font-size: 24px;
}

#hero .btn-get-started {
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 28px 11px 28px;
  border-radius: 50px;
  transition: 0.5s;
  margin: 10px 0 0 0;
  color: #fff;
  background: #47b2e4;
}

#hero .btn-get-started:hover {
  background: #209dd8;
}

#hero .btn-watch-video {
  font-size: 16px;
  display: flex;
  align-items: center;
  transition: 0.5s;
  margin: 10px 0 0 25px;
  color: #fff;
  line-height: 1;
}

#hero .btn-watch-video i {
  line-height: 0;
  color: #fff;
  font-size: 32px;
  transition: 0.3s;
  margin-right: 8px;
}

#hero .btn-watch-video:hover i {
  color: #47b2e4;
}

#hero .animated {
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}

@media (max-width: 991px) {
  #hero {
    height: 100vh;
    text-align: center;
  }

  #hero .animated {
    -webkit-animation: none;
    animation: none;
  }

  #hero .hero-img {
    text-align: center;
  }

  #hero .hero-img img {
    width: 50%;
  }
}

@media (max-width: 768px) {
  #hero h1 {
    font-size: 28px;
    line-height: 36px;
  }

  #hero h2 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
  }

  #hero .hero-img img {
    width: 70%;
  }
}

@media (max-width: 575px) {
  #hero .hero-img img {
    width: 80%;
  }

  #hero .btn-get-started {
    font-size: 16px;
    padding: 10px 24px 11px 24px;
  }
}
.enform {
  width: 100%;
}
@-webkit-keyframes up-down {
  0% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(-10px);
  }
}

@keyframes up-down {
  0% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(-10px);
  }
}

.whyCan_image {
  cursor: pointer;
  animation: rotation 15s infinite linear;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

section {
  /* padding: 20px 0; */
  overflow: hidden;
}

.section-bg {
  background-color: #f3f5fa;
}

.section-blue-bg {
  background-color: #2d2f58cc;
}

.section-service-bg {
  background: transparent;
}

.section-title {
  text-align: center;
  padding-bottom: 0px;
}

.section-title h2 {
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  color: #37517e;
}

.section-title h2::before {
  content: "";
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 60px);
}

.section-title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: #19155b;
  bottom: 0;
  left: calc(50% - 20px);
}

.section-title p {
  margin-bottom: 0;
}

.section-title-about {
  text-align: left;
  padding-bottom: 30px;
}

.section-title-about h2 {
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  margin-left: 20px;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  color: #37517e;
}

.section-title-about h2::before {
  content: "";
  position: absolute;
  display: block;
  width: 120px;

  background: #ddd;
  bottom: 1px;
}
.appmo {
  font-weight: bolder;
  color: #0c90ec;
  padding-bottom: 10px;
  padding-left: 10px;
  border-bottom: 3px solid yellow;
  width: fit-content;
}
.section-title-service {
  text-align: left;

  box-sizing: border-box;
  padding-top: 50px;
  color: #fff;
  background-image: url("http://www.canvendor.com/assets/images/services/ai1.png");
  background-repeat: no-repeat;
  height: 70vh;

  background-size: cover;
}
.section-title-serviceCloud {
  text-align: left;

  box-sizing: border-box;
  padding-top: 50px;
  color: #fff;
  background-image: url("http://www.canvendor.com/assets/images/services/clban.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 80vh;
}
.section-title-serviceDigital {
  text-align: left;

  box-sizing: border-box;
  padding-top: 50px;
  color: #fff;

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 80vh;
}
.service1 {
  background-size: cover;
  background-position: center;
  background-image: url("http://www.canvendor.com/assets/images/services/app.jpg");
}
.service-app h3 {
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;

  position: relative;
  color: #fff;

  border-bottom: 1px solid yellow;
  width: fit-content;
}
.section-title-service h2 {
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 20px;

  color: #fff;

  border-bottom: 1px solid yellow;
  width: fit-content;
}

.section-title-service h2::before {
  content: "";

  display: block;

  background: #ddd;
}
.aiml-service-content {
  background-color: rgba(255, 255, 255, 0.3);
  width: 50%;

  border-left: 5px solid yellow;
  padding: 25px;
}

.cloud-service-content {
  background-color: rgba(255, 255, 255, 0.3);
  width: 75%;

  border-left: 5px solid yellow;
  padding: 25px;
}
.digital-service-content {
  background-color: rgba(255, 255, 255, 0.3);
  width: 60%;

  border-left: 5px solid yellow;
  padding: 25px;
}

#about {
  padding-top: 40px;
  padding-bottom: 0px;
  position: relative;
  z-index: -2;
}

.location-section {
  height: 300px;
}
.location-content {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 50vh;
}

.com-loc {
  position: relative;
}

.america {
  background-image: linear-gradient(#9294a900, #1f2148),
    url("../public/assets/images/home/locations/america.jpg");
  background-size: cover;
}

.india {
  background-image: linear-gradient(#9294a900, #1f2148),
    url("../public/assets/images/home/locations/india.jpg");
  background-size: cover;
}

.canada {
  background-image: linear-gradient(#9294a900, #1f2148),
    url("../public/assets/images/home/locations/canada.jpg");
  background-size: cover;
}

.uk {
  background-image: linear-gradient(#9294a900, #1f2148),
    url("../public/assets/images/home/locations/uk.jpg");
  background-size: cover;
}

.uae {
  background-image: linear-gradient(#9294a900, #1f2148),
    url("../public/assets/images/home/locations/location-bg-1.png");
  background-size: cover;
}

.clients {
  padding: 12px 0;
  text-align: center;
}

.clients img {
  max-width: 45%;
  transition: all 0.4s ease-in-out;
  display: inline-block;
  padding: 15px 0;
}

.clients img:hover {
  filter: none;
  transform: scale(1.1);
}

@media (max-width: 768px) {
  .clients img {
    max-width: 40%;
  }
}

.about .content h3 {
  font-weight: 600;
  font-size: 26px;
}

.about .content ul {
  list-style: none;
  padding: 0;
}

.about .content ul li {
  padding-left: 28px;
  position: relative;
}

.about .content ul li + li {
  margin-top: 10px;
}

.about .content ul i {
  position: absolute;
  left: 0;
  top: 2px;
  font-size: 20px;
  color: #47b2e4;
  line-height: 1;
}

.about .content p:last-child {
  margin-bottom: 0;
}

.about .content .btn-learn-more {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 32px;
  border-radius: 4px;
  transition: 0.3s;
  line-height: 1;
  color: #47b2e4;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
  margin-top: 6px;
  border: 2px solid #47b2e4;
}

.about .content .btn-learn-more:hover {
  background: #47b2e4;
  color: #fff;
  text-decoration: none;
}

.why-us {
  padding-top: 60px !important;
  background: rgb(31, 33, 72);
  background: linear-gradient(
    27deg,
    rgba(31, 33, 72, 1) 55%,
    rgba(24, 63, 113, 1) 91%
  );
}
#whyconten {
  padding-left: 60px;
  padding-top: 40px;
}
.why-us .content {
  padding: 0 60px 0 60px;
  margin-bottom: 20px;
}

.why-us .content h3 {
  font-weight: 400;
  font-size: 44px;
  line-height: 2em;
  color: #37517e;
}

.why-us .content h4 {
  font-size: 20px;
  font-weight: 700;
  margin-top: 5px;
}

.why-us .content p {
  font-size: 15px;
  line-height: 2em;
  color: #848484;
}

.why-us .img {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.why-us .accordion-list {
  padding: 0 60px 60px 60px;
}

.why-us .accordion-list ul {
  padding: 0;
  list-style: none;
}

.why-us .accordion-list li + li {
  margin-top: 15px;
}

.why-us .accordion-list li {
  padding: 20px;
  background: #fff;
  border-radius: 4px;
}

.why-us .accordion-list a {
  display: block;
  position: relative;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  padding-right: 30px;
  outline: none;
  cursor: pointer;
}

.why-us .accordion-list span {
  color: #47b2e4;
  font-weight: 600;
  font-size: 18px;
  padding-right: 10px;
}

.why-us .accordion-list i {
  font-size: 24px;
  position: absolute;
  right: 0;
  top: 0;
}

.why-us .accordion-list p {
  margin-bottom: 0;
  padding: 10px 0 0 0;
}

.why-us .accordion-list .icon-show {
  display: none;
}

.why-us .accordion-list a.collapsed {
  color: #343a40;
}

.why-us .accordion-list a.collapsed:hover {
  color: #47b2e4;
}

.why-us .accordion-list a.collapsed .icon-show {
  display: inline-block;
}

.why-us .accordion-list a.collapsed .icon-close {
  display: none;
}

@media (max-width: 1024px) {
  .why-us .content,
  .why-us .accordion-list {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 992px) {
  .why-us .img {
    min-height: 400px;
  }
  .serv_item {
    width: 100% !important;
  }

  .why-us .content {
    padding-top: 30px;
  }

  .why-us .accordion-list {
    padding-bottom: 30px;
  }
}

@media (max-width: 575px) {
  .why-us .img {
    min-height: 200px;
  }
}

.skills .content h3 {
  font-weight: 700;
  font-size: 32px;
  color: #37517e;
  font-family: "Poppins", sans-serif;
}

.skills .content ul {
  list-style: none;
  padding: 0;
}

.skills .content ul li {
  padding-bottom: 10px;
}

.skills .content ul i {
  font-size: 20px;
  padding-right: 4px;
  color: #47b2e4;
}

.skills .content p:last-child {
  margin-bottom: 0;
}

.skills .progress {
  height: 60px;
  display: block;
  background: none;
  border-radius: 0;
}

.skills .progress .skill {
  padding: 0;
  margin: 0 0 6px 0;
  text-transform: uppercase;
  display: block;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #37517e;
}

.skills .progress .skill .val {
  float: right;
  font-style: normal;
}

.skills .progress-bar-wrap {
  background: #e8edf5;
  height: 10px;
}

.skills .progress-bar {
  width: 1px;
  height: 10px;
  transition: 0.9s;
  background-color: #4668a2;
}

#extraindus {
  width: 100%;
  height: 300px;
  background-color: #000048;
}
.industry-card {
  box-shadow: 0px 0 25px 0 rgba(0, 0, 0, 0.1);
  padding: 50px 30px;
  transition: all ease-in-out 0.4s;
  background: #fff;
}

.industry-card:hover {
  transform: translateY(-10px);
}

.about-section-image {
  height: inherit;
  max-width: 100%;
  max-height: 100vh;
}

.col-6-abs {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.about-container {
  height: inherit;
}

.about-bg {
  padding: 10px;
  background-color: #fff;

  box-shadow: 0 0 80px rgba(14, 14, 71, 0.5);

  align-items: center;
  transition: transform 0.2s;
  cursor: pointer;
  border-radius: 8px;
}

.about-bg:hover {
  -ms-transform: scale(1.05);
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}
/* .about-content{

   padding-bottom: 25px !important; 
   padding-left: 16px !important;
   padding-right: 16px !important;
  } */

.section-color-gradient {
  background-image: linear-gradient(#ffffff, #9294a938),
    url("http://www.canvendor.com/assets/images/about/about-bg-9.png");
  animation: slide 50s linear infinite;

  background-size: cover;
  background-position: center center;
}

@keyframes slide {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 100% 0%;
  }
}

.services .icon-box {
  box-shadow: 0px 0 25px 0 rgba(0, 0, 0, 0.1);
  padding: 50px 30px;
  transition: all ease-in-out 0.4s;
  background: #fff;
}

.services .icon-box .icon {
  margin-bottom: 10px;
}

.services .icon-box .icon i {
  color: #47b2e4;
  font-size: 36px;
  transition: 0.3s;
}

.services .icon-box h4 {
  font-weight: 500;
  margin-bottom: 15px;
  font-size: 24px;
}

.services .icon-box h4 a {
  color: #37517e;
  transition: ease-in-out 0.3s;
}

.services .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.services .icon-box:hover {
  /* transform: translateY(-10px); */
}

.services .icon-box:hover h4 a {
  /* color: #47b2e4; */
}

.services .icon-box-services {
  box-shadow: 0px 0 25px 0 rgba(0, 0, 0, 0.1);
  padding: 50px 30px;
  padding-bottom: 20px;
  transition: all ease-in-out 0.4s;
  position: relative;
  z-index: 1;
  background-color: rgb(255, 255, 255);
  /* border:3px solid #a7e6ff; */
  text-align: center;
  border-radius: 5px;
  height: 300px;
  width: 100%;
}

.carousel-control-next {
  display: none;
}
.carousel-control-prev {
  display: none;
}
.carousel-indicators {
  display: none;
}
.services .icon-box-services:hover {
  /* box-shadow: 0px 0 25px 0 rgba(0, 0, 0, 0.1); */
  padding: 50px 30px;
  transition: all ease-in-out 0.4s;
  transform: scale(1.1);
  background: white;
  cursor: pointer;
  border: 3px solid #fff;
}

.services .icon-box-services .icon {
  margin-bottom: 10px;
}

.services .icon-box-services .icon i {
  color: #fff;
  font-size: 36px;
  transition: 0.3s;
}

.services .icon-box-services h4 {
  font-weight: 500;
  margin-bottom: 15px;
  font-size: 24px;
}

.services .icon-box-services h4 a {
  color: #040c19;
  font-weight: bold;
  transition: ease-in-out 0.3s;
}

.services .icon-box-services p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;

  color: gray;
}

.services .icon-box-services:hover p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
  /* color: black; */
}

.services .icon-box-services:hover h4 a {
  /* color: #47b2e4; */
  color: #040c19;
}

.slider {
  position: relative;
  width: 100%;
  height: 200px;
}

.slider-wrapper {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 200%;
  height: 100%;
  animation: sliding 10s linear infinite;
}

.slider div {
  flex-shrink: 0;
  width: 50%;
  height: 100%;
}

@keyframes sliding {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.services .icon-box-insights {
  box-shadow: 0px 0 25px 0 rgba(0, 0, 0, 0.1);

  transition: all ease-in-out 0.4s;
  background: #fff;
}

.services .icon-box-insights .icon {
  margin-bottom: 10px;
}

.services .icon-box-insights .icon i {
  color: #47b2e4;
  font-size: 36px;
  transition: 0.3s;
}

.services .icon-box-insights h4 {
  font-weight: 500;
  margin-bottom: 15px;
  font-size: 24px;
  padding: 5px 30px;
}

.services .icon-box-insights h4 a {
  color: #37517e;
  transition: ease-in-out 0.3s;
}

.services .icon-box-insights p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
  padding: 0px 30px;
}

.services .icon-box-insights:hover {
  transform: translateY(-10px);
}

.services .icon-box-insights:hover h4 a {
  color: #47b2e4;
}

.cta {
  background-size: cover;
  padding: 120px 0;
}

.cta h3 {
  color: #fff;
  font-size: 28px;
  font-weight: 700;
}

.cta p {
  color: #fff;
}

.cta .cta-btn {
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 40px;
  border-radius: 50px;
  transition: 0.5s;
  margin: 10px;
  border: 2px solid #fff;
  color: #fff;
}

.cta .cta-btn:hover {
  background: #47b2e4;
  border: 2px solid #47b2e4;
}

@media (max-width: 1024px) {
  .cta {
    background-attachment: scroll;
  }
}

@media (min-width: 769px) {
  .cta .cta-btn-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.portfolio #portfolio-flters {
  list-style: none;
  margin-bottom: 20px;
}

.portfolio #portfolio-flters li {
  cursor: pointer;
  display: inline-block;
  margin: 10px 5px;
  font-size: 15px;
  font-weight: 500;
  line-height: 1;
  color: #444444;
  transition: all 0.3s;
  padding: 8px 20px;
  border-radius: 50px;
  font-family: "Poppins", sans-serif;
}

.portfolio #portfolio-flters li:hover,
.portfolio #portfolio-flters li.filter-active {
  background: #47b2e4;
  color: #fff;
}

.portfolio .portfolio-item {
  margin-bottom: 30px;
}

.portfolio .portfolio-item .portfolio-img {
  overflow: hidden;
}

.portfolio .portfolio-item .portfolio-img img {
  transition: all 0.6s;
}

.portfolio .portfolio-item .portfolio-info {
  opacity: 0;
  position: absolute;
  left: 15px;
  bottom: 0;
  z-index: 3;
  right: 15px;
  transition: all 0.3s;
  background: rgba(55, 81, 126, 0.8);
  padding: 10px 15px;
}

.portfolio .portfolio-item .portfolio-info h4 {
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  color: #fff;
  margin-bottom: 0px;
}

.portfolio .portfolio-item .portfolio-info p {
  color: #f9fcfe;
  font-size: 14px;
  margin-bottom: 0;
}

.portfolio .portfolio-item .portfolio-info .preview-link,
.portfolio .portfolio-item .portfolio-info .details-link {
  position: absolute;
  right: 40px;
  font-size: 24px;
  top: calc(50% - 18px);
  color: #fff;
  transition: 0.3s;
}

.portfolio .portfolio-item .portfolio-info .preview-link:hover,
.portfolio .portfolio-item .portfolio-info .details-link:hover {
  color: #47b2e4;
}

.portfolio .portfolio-item .portfolio-info .details-link {
  right: 10px;
}

.portfolio .portfolio-item:hover .portfolio-img img {
  transform: scale(1.15);
}

.portfolio .portfolio-item:hover .portfolio-info {
  opacity: 1;
}

.portfolio-details {
  padding-top: 40px;
}

.portfolio-details .portfolio-details-slider img {
  width: 100%;
}

.portfolio-details .portfolio-details-slider .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.portfolio-details
  .portfolio-details-slider
  .swiper-pagination
  .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #47b2e4;
}

.portfolio-details
  .portfolio-details-slider
  .swiper-pagination
  .swiper-pagination-bullet-active {
  background-color: #47b2e4;
}

.portfolio-details .portfolio-info {
  padding: 30px;
  box-shadow: 0px 0 30px rgba(55, 81, 126, 0.08);
}

.portfolio-details .portfolio-info h3 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}

.portfolio-details .portfolio-info ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.portfolio-details .portfolio-info ul li + li {
  margin-top: 10px;
}

.portfolio-details .portfolio-description {
  padding-top: 30px;
}

.portfolio-details .portfolio-description h2 {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
}

.portfolio-details .portfolio-description p {
  padding: 0;
}

.team .member {
  position: relative;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  padding: 30px;
  border-radius: 5px;
  background: #fff;
  transition: 0.5s;
}

.team .member .pic {
  overflow: hidden;
  width: 180px;
  border-radius: 50%;
}

.team .member .pic img {
  transition: ease-in-out 0.3s;
}

.team .member:hover {
  transform: translateY(-10px);
}

.team .member .member-info {
  padding-left: 30px;
}

.team .member h4 {
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 20px;
  color: #37517e;
}

.team .member span {
  display: block;
  font-size: 15px;
  padding-bottom: 10px;
  position: relative;
  font-weight: 500;
}

.team .member span::after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 1px;
  background: #cbd6e9;
  bottom: 0;
  left: 0;
}

.team .member p {
  margin: 10px 0 0 0;
  font-size: 14px;
}

.team .member .social {
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.team .member .social a {
  transition: ease-in-out 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  width: 32px;
  height: 32px;
  background: #eff2f8;
}

.team .member .social a i {
  color: #37517e;
  font-size: 16px;
  margin: 0 2px;
}

.team .member .social a:hover {
  background: #47b2e4;
}

.team .member .social a:hover i {
  color: #fff;
}

.team .member .social a + a {
  margin-left: 8px;
}

.pricing .row {
  /* padding-top: 40px; */
}
.amiservice p {
  text-align: left;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.65;

  overflow-wrap: break-word;
}
.amiservice .amip {
  padding-top: 20px;
  font-size: 18px;
  padding-left: 20px;
  padding-right: 20px;
}

.pricing .box {
  padding: 60px 40px;
  box-shadow: 0 3px 20px -2px rgba(20, 45, 100, 0.1);
  background: #fff;
  height: 100%;
  border-top: 4px solid #fff;
  border-radius: 5px;
}

.pricing h4 {
  font-size: 48px;
  color: #37517e;
  font-weight: 400;

  margin-bottom: 25px;
}

.pricing h4 sup {
  font-size: 28px;
}

.pricing h4 span {
  color: #47b2e4;
  font-size: 18px;
  display: block;
}

.pricing ul {
  padding: 20px 0;
  list-style: none;
  color: #999;
  text-align: left;
  line-height: 20px;
}

.pricing ul li {
  padding: 10px 0 10px 30px;
  position: relative;
}

.pricing ul i {
  color: #28a745;
  font-size: 24px;
  position: absolute;
  left: 0;
  top: 6px;
}

.pricing ul .na {
  color: #ccc;
}

.pricing ul .na i {
  color: #ccc;
}

.pricing ul .na span {
  text-decoration: line-through;
}

.pricing .buy-btn {
  display: inline-block;
  padding: 12px 35px;
  border-radius: 50px;
  color: #47b2e4;
  transition: none;
  font-size: 16px;
  font-weight: 500;

  transition: 0.3s;
  border: 1px solid #47b2e4;
}

.pricing .buy-btn:hover {
  background: #47b2e4;
  color: #fff;
}

.pricing .featured {
  border-top-color: #47b2e4;
}

.pricing .featured .buy-btn {
  background: #47b2e4;
  color: #fff;
}

.pricing .featured .buy-btn:hover {
  background: #23a3df;
}

@media (max-width: 992px) {
  .pricing .box {
    max-width: 60%;
    margin: 0 auto 30px auto;
  }
}

@media (max-width: 767px) {
  .pricing .box {
    max-width: 80%;
    margin: 0 auto 30px auto;
  }
}

@media (max-width: 420px) {
  .pricing .box {
    max-width: 100%;
    margin: 0 auto 30px auto;
  }
}

.faq .faq-list {
  padding: 0 100px;
}

.faq .faq-list ul {
  padding: 0;
  list-style: none;
}

.faq .faq-list li + li {
  margin-top: 15px;
}

.faq .faq-list li {
  padding: 20px;
  background: #fff;
  border-radius: 4px;
  position: relative;
}

.faq .faq-list a {
  display: block;
  position: relative;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  padding: 0 30px;
  outline: none;
  cursor: pointer;
}

.faq .faq-list .icon-help {
  font-size: 24px;
  position: absolute;
  right: 0;
  left: 20px;
  color: #47b2e4;
}

.faq .faq-list .icon-show,
.faq .faq-list .icon-close {
  font-size: 24px;
  position: absolute;
  right: 0;
  top: 0;
}

.faq .faq-list p {
  margin-bottom: 0;
  padding: 10px 0 0 0;
}

.faq .faq-list .icon-show {
  display: none;
}

.faq .faq-list a.collapsed {
  color: #37517e;
  transition: 0.3s;
}

.faq .faq-list a.collapsed:hover {
  color: #47b2e4;
}

.faq .faq-list a.collapsed .icon-show {
  display: inline-block;
}

.faq .faq-list a.collapsed .icon-close {
  display: none;
}

@media (max-width: 1200px) {
  .faq .faq-list {
    padding: 0;
  }
}

.contact .info {
  border-top: 3px solid #47b2e4;
  border-bottom: 3px solid #47b2e4;
  padding: 30px;
  background: #fff;
  width: 100%;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1);
}

.contact .info i {
  font-size: 20px;
  color: #47b2e4;
  float: left;
  width: 44px;
  height: 44px;
  background: #e7f5fb;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}

.contact .info h4 {
  padding: 0 0 0 60px;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #37517e;
}

.contact .info p {
  padding: 0 0 10px 60px;
  margin-bottom: 20px;
  font-size: 14px;
  color: #6182ba;
}

.contact .info .email p {
  padding-top: 5px;
}

.contact .info .social-links {
  padding-left: 60px;
}

.contact .info .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #333;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
  margin-right: 10px;
}

.contact .info .social-links a:hover {
  background: #47b2e4;
  color: #fff;
}

.contact .info .email:hover i,
.contact .info .address:hover i,
.contact .info .phone:hover i {
  background: #47b2e4;
  color: #fff;
}

.contact .php-email-form {
  width: 100%;
  border-top: 3px solid #47b2e4;
  border-bottom: 3px solid #47b2e4;
  padding: 30px;
  background: #fff;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
}

.contact .php-email-form .form-group {
  padding-bottom: 8px;
}

.contact .php-email-form .validate {
  display: none;
  color: red;
  margin: 0 0 15px 0;
  font-weight: 400;
  font-size: 13px;
}

.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .error-message br + br {
  margin-top: 25px;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form .form-group {
  margin-bottom: 20px;
}

.contact .php-email-form label {
  padding-bottom: 8px;
}
a:hover {
  color: #f99e2e !important;
}
.contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  border-radius: 4px;
}
textarea {
  outline: none;
}
.contact .php-email-form input:focus,
.contact .php-email-form textarea:focus {
  border-color: #47b2e4;
}

.contact .php-email-form input {
  height: 44px;
}

.contact .php-email-form textarea {
  padding: 10px 12px;
}
.enquriy .enform input {
  border-top: none;
  border-left: none;
  border-right: none;
  outline: none;
  border-radius: none !important;
  margin-left: 30px;
  margin-top: 20px;
}
.enimage {
  filter: drop-shadow(5px 5px 20px gray);
}
button {
  background-color: rgb(31, 33, 72);
  /* background: #47b2e4; */
  border: 0;
  padding: 12px 34px;
  color: #fff;
  transition: 0.4s;
  /* border-radius: 50px; */
}

input[type="submit"] {
  background: #47b2e4;
  border: 0;
  padding: 12px 34px;
  color: #fff;
  transition: 0.4s;
  border-radius: 50px;
}
.contact .php-email-form button[type="submit"] {
  background: #47b2e4;
  border: 0;
  padding: 12px 34px;
  color: #fff;
  transition: 0.4s;
  border-radius: 50px;
}

.contact .php-email-form button[type="submit"]:hover {
  background: #209dd8;
}

@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.breadcrumbs {
  padding: 15px 0;
  background: #f3f5fa;
  min-height: 40px;
  margin-top: 72px;
}

@media (max-width: 992px) {
  .breadcrumbs {
    margin-top: 68px;
  }
}

.breadcrumbs h2 {
  font-size: 28px;
  font-weight: 600;
  color: #37517e;
}

.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0 0 10px 0;
  margin: 0;
  font-size: 14px;
}

.breadcrumbs ol li + li {
  padding-left: 10px;
}

.breadcrumbs ol li + li::before {
  display: inline-block;
  padding-right: 10px;
  color: #4668a2;
}

#footer {
  position: relative;
  background-color: white;
  font-size: 14px;
  /* background: rgb(0,15,49);
    background: linear-gradient(45deg, rgba(0,15,49,1) 50%, rgba(0,25,83,1) 84%, rgba(0,25,84,1) 100%); */
  /* background: #2c2e4f; */
}
#cpy {
  background: rgb(0, 15, 49);
  background: linear-gradient(
    45deg,
    rgba(0, 15, 49, 1) 50%,
    rgba(0, 25, 83, 1) 84%,
    rgba(0, 25, 84, 1) 100%
  );
  width: 100%;
}
#footer .footer-newsletter {
  /*   
    background: #1f2148; */

  text-align: center;
  font-size: 15px;
  color: #444444;
}

#footer .footer-newsletter h4 {
  font-size: 24px;
  margin: 0 0 20px 0;
  padding: 0;
  line-height: 1;
  font-weight: 600;
  color: #37517e;
}

#footer .footer-newsletter form {
  margin-top: 30px;
  background: #fff;
  padding: 6px 10px;
  position: relative;
  border-radius: 50px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.06);
  text-align: left;
}

#footer .footer-newsletter form input[type="email"] {
  border: 0;
  padding: 4px 8px;
  width: calc(100% - 100px);
}

#footer .footer-newsletter form input[type="submit"] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 20px;
  background: #47b2e4;
  color: #fff;
  transition: 0.3s;
  border-radius: 50px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

#footer .footer-newsletter form input[type="submit"]:hover {
  background: #209dd8;
}

#footer .footer-top {
  padding: 60px 0 30px 0;
}
#footer .footer-top {
  padding: 60px 0 30px 0;
  background-image: url("/public/company-skill-bg.png");
  width: 100%;

  background-size: auto;
}
#footer .footer-lay {
}
#footer .footer-top .footer-contact {
  margin-bottom: 30px;
}

#footer .footer-top .footer-contact h3 {
  font-size: 28px;
  margin: 0 0 10px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  text-transform: uppercase;
  font-weight: 600;
  color: #37517e;
}

#footer .footer-top .footer-contact p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;

  color: #5e5e5e;
}

/* #footer .footer-top h4 {
  font-size: 16px;
  font-weight: bold;
  position: relative;
  padding-bottom: 12px;
} */

#footer .footer-top .footer-links {
  margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#footer .footer-top .footer-links ul i {
  padding-right: 2px;
  color: #47b2e4;
  font-size: 18px;
  line-height: 1;
}

#footer .footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

/* #footer .footer-top .footer-links ul a {
  color: black;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
} */

/* #footer .footer-top .footer-links ul a:hover {
  text-decoration: none;
  color: #47b2e4;
} */

#footer .footer-top .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #47b2e4;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

#footer .footer-top .social-links a:hover {
  background: #209dd8;
  color: #fff;
  text-decoration: none;
}

#footer .footer-bottom {
  padding-top: 16px;
  padding-bottom: 16px;
  color: #fff;
}

#footer .credits {
  float: right;
  font-size: 13px;
}

#footer .credits a {
  transition: 0.3s;
}

.chalkboard {
  background-color: #1b1b1b;

  background-size: 30px 30px;
  color: #fff;
  padding: 2rem;
  position: relative;
}

.chalkboard::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5),
    0 0 20px rgba(255, 255, 255, 0.5), 0 0 30px rgba(255, 255, 255, 0.5);
  z-index: -1;
  border-radius: 5px;
}

@media (max-width: 768px) {
  #footer .footer-bottom {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  #footer .copyright,
  #footer .credits {
    text-align: center;
    float: none;
  }

  #footer .credits {
    padding-top: 4px;
  }
}
.arrows {
  width: 60px;
  height: 72px;
  position: absolute;
  left: 50%;
  margin-left: -30px;
  bottom: 20px;
}

.arrows path {
  stroke: #e1e7ea;
  fill: transparent;
  stroke-width: 2px;
  animation: arrow 2s infinite;
  -webkit-animation: arrow 2s infinite;
}

@keyframes arrow {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes arrow {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.arrows path.a1 {
  animation-delay: -1s;
  -webkit-animation-delay: -1s;
}

.arrows path.a2 {
  animation-delay: -0.5s;
  -webkit-animation-delay: -0.5s;
}

.arrows path.a3 {
  animation-delay: 0s;
  -webkit-animation-delay: 0s;
}
.overlay {
  opacity: 0;
  font-size: 20px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  color: #dee2e3;
  background-color: #00004884;
  -webkit-transition: all 400ms ease-out;
  -moz-transition: all 400ms ease-out;
  -o-transition: all 400ms ease-out;
  -ms-transition: all 400ms ease-out;
  transition: all 400ms ease-out;
  text-align: center;
}
.overlay p {
  padding: 10px;
}
.com-loc:hover .overlay {
  visibility: visible;
  opacity: 1.8;
}
.com-loc:hover .locName {
  visibility: hidden;
  opacity: 0;
}
#services {
  width: 100%;
  /* background-color: rgb(239, 241, 247); */
  /* background-color:#eee; */
  /* background-color:#eee; */
  /* background: linear-gradient(27deg, rgba(31,33,72,1) 55%, rgba(24,63,113,1) 91%);
    background-image:linear-gradient(27deg, rgba(31,33,72,1) 55%, rgba(24,63,113,1) 91%), url('http://www.canvendor.com/assets/images/about/istockphoto-1431862546-170667a.jpg');
    background-size: cover; */
}

#recent-blog-posts {
  background: rgb(31, 33, 72);
  background: linear-gradient(
    27deg,
    rgba(31, 33, 72, 1) 55%,
    rgba(24, 63, 113, 1) 91%
  );
}

#blognew h2 {
  color: #1f2148;
}
.post-item img {
  border-radius: 10px;
}
.post-item {
  border-radius: 10px;
  background-color: #e9eaec;
  box-shadow: 5px 5px 15px 0px gray;
}

.recent-blog-posts .post-item {
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.06);
  transition: 0.3s;
}

.recent-blog-posts .post-item .post-img img {
  transition: 0.5s;
}

.recent-blog-posts .post-item .post-date {
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: var(--color-primary);
  color: #fff;
  text-transform: uppercase;
  font-size: 13px;
  padding: 6px 12px;
  font-weight: 500;
}

.recent-blog-posts .post-item .post-content {
  padding: 30px;
}

.recent-blog-posts .post-item .post-title {
  font-size: 24px;
  color: var(--color-secondary);
  font-weight: 700;
  transition: 0.3s;
  margin-bottom: 15px;
}

.recent-blog-posts .post-item .meta i {
  font-size: 16px;
  color: var(--color-primary);
}

.recent-blog-posts .post-item .meta span {
  font-size: 15px;
  color: #838893;
}

.recent-blog-posts .post-item hr {
  color: #888;
  margin: 20px 0;
}

.recent-blog-posts .post-item .readmore {
  display: flex;
  align-items: center;
  font-weight: 600;
  line-height: 1;
  transition: 0.3s;
  color: #838893;
}

.recent-blog-posts .post-item .readmore i {
  line-height: 0;
  margin-left: 6px;
  font-size: 16px;
}

.recent-blog-posts .post-item:hover .post-title,
.recent-blog-posts .post-item:hover .readmore {
  color: var(--color-primary);
}

.recent-blog-posts .post-item:hover .post-img img {
  transform: scale(1.1);
}

.footer-newsletter h4 {
  font-size: 24px;
  margin: 0 0 10px 0;
  padding: 0;

  font-weight: 600;
}

.footer-newsletter form {
  background: #fff;
  padding: 6px 10px;
  position: relative;
  border-radius: 50px;
}
.footer-newsletter form input[type="email"] {
  border: 0;
  padding: 8px;
  width: calc(100% - 140px);
  outline: none;
}

.footer-newsletter form input[type="submit"] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 30px;
  margin: 3px;
  background: #e96b56;
  color: #fff;
  transition: 0.3s;
  border-radius: 50px;
}

.footer-newsletter form input[type="submit"]:hover {
  background: #e6573f;
}

#talk {
  margin-bottom: 0px !important;
}

#newreadmore1 {
  color: white;
}
.newreadmore {
  border-radius: 4px;

  border: none;
  color: white;
  text-align: center;
  font-size: 15px;
  padding: 10px;
  width: 200px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
}

.newreadmore span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.newreadmore span:after {
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.newreadmore:hover span {
  padding-right: 25px;
}

.newreadmore:hover span:after {
  opacity: 1;
  right: 0;
}
.locName {
  position: absolute;
  bottom: 0px;
  text-align: center;
  padding: 20px;
  background-color: #209ed8a6;
  color: white;
  width: 100%;
  height: 100px;
  transition: 1s ease-out;
}
.locName h2 {
  font-size: 23px;
}

#whyabout {
  margin-top: 40px;
  background-color: #d6d8da;
}

.carrer h2 {
  font-weight: 700;
}

@media (min-width: 991px) {
  .carrer h2 {
    max-width: 75%;

    margin-bottom: 30px;
  }
}

.carrer .our-storycar {
  padding: 40px;
  background-color: #d6d8da;
}

@media (min-width: 991px) {
  .carrer .our-storycar {
    padding-right: 35%;
  }
}

.carrer .our-storycar h4 {
  text-transform: uppercase;
  font-size: 18px;
  color: #838893;
}

.carrer .our-storycar h3 {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 30px;
  color: var(--color-secondary);
}

.carrer .our-storycar p:last-child {
  margin-bottom: 0;
}

.carrer ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.carrer ul li {
  padding: 5px 0;
  display: flex;
  align-items: center;
}

.carrer ul i {
  font-size: 20px;
  margin-right: 4px;
  color: var(--color-primary);
}

.carrer .carrer-img {
  min-height: 600px;
  background-size: cover;
  background-position: center;
}

@media (min-width: 992px) {
  .carrer .carrer-img {
    position: absolute;
    top: 0;
    right: 0;
  }
}
.carrer-img {
  background-image: url("http://www.canvendor.com/assets/images/blog/Life-at-Capgemini-Invent-2880X1800Pxl.jpg");
  height: 600px;
  transition: 2s ease-in-out;
  border-radius: 20px;
}
.about-img:hover {
  -ms-transform: scale(1.05); /* IE 9 */
  -webkit-transform: scale(1.05); /* Safari 3-8 */
  transform: scale(1.05);
}

.tile {
  width: 20%;
  height: auto;
  display: inline-block;
  background-size: cover;
  cursor: pointer;
  position: relative;
  float: left;
  padding: 0 0 19% 0;
  background-position: center;
}

.image-title {
  width: 100%;
  height: 25%;
  color: white;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0 1rem 1rem;
  font-size: 0.9em;

  text-align: center;
  line-height: 1em;
  background-color: rgba(0, 0, 0, 0.5);
}

.tile .image-title {
  transition: 0.8s ease;
}

.tile:hover .image-title {
  background-color: rgba(0, 0, 0, 0.8);
  height: 40%;
}

@media screen and (max-width: 1280px) {
  .image-container .tile {
    width: 25%;
    padding-bottom: 25%;
  }
}

@media screen and (max-width: 1024px) {
  .image-container .tile {
    width: 33.33%;
    padding-bottom: 33.33%;
  }
}

@media screen and (max-width: 740px) {
  .image-container .tile {
    font-size: 0.8em;
  }
}

@media screen and (max-width: 600px) {
  .image-container .tile {
    width: 50%;
    padding-bottom: 50%;
    font-size: 0.8em;
  }
}

@media screen and (max-width: 360px) {
  .image-container .tile {
    width: 100%;
    padding-bottom: 100%;
  }
}

.homeindus {
  width: 407px;
  height: 450px;
  margin: 0 auto;

  position: relative;
  transition: all 0.5s;
  overflow: hidden;
}
.indushover {
  border: 3px solid #eee;
  width: 407px;
  cursor: pointer;
  height: 400px;
  transition: all 0.5s;

  overflow: hidden;
  background-size: cover;
  background-position: center;
}
.indushover:hover {
  -ms-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
}

.servicecard .backgroundEffect {
  bottom: 0;
  left: 0;
  height: 0px;
  /* background-color: #00004800;
  border: none; */
  width: 100%;
}

.servicecard:hover {
  color: #fff;
  transform: scale(1.025);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 5px 10px;
}

.servicecard:hover .backgroundEffect {
  bottom: 0;
  height: 298px;
  /* width: %; */
  position: absolute;
  z-index: -1;
  background: #1b9ce3;
  color: white;
  animation: popBackground 0.5s ease-in;
}

@keyframes popBackground {
  0% {
    height: 20px;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
  }

  50% {
    height: 80px;
    border-top-left-radius: 75%;
    border-top-right-radius: 75%;
  }

  75% {
    height: 160px;
    border-top-left-radius: 85%;
    border-top-right-radius: 85%;
  }

  100% {
    height: 298px;
    border-top-left-radius: 100%;
    border-top-right-radius: 100%;
  }
}

.semi {
  /* box-shadow: 5px 5px 10px 10px #b7b7bafe; */
  transition: all 0.5s;
  background-size: cover;
  background-position: center;
  /* background-color: #000048; */
  background-image: url("https://keenitsolutions.com/products/wordpress/braintech/wp-content/uploads/2020/11/pro_06-500x500.jpg");
}
.manu {
  background-image: url("https://keenitsolutions.com/products/wordpress/braintech/wp-content/uploads/2020/11/pro_06-500x500.jpg");
}
.auto {
  background-image: url("https://keenitsolutions.com/products/wordpress/braintech/wp-content/uploads/2020/11/pro_06-500x500.jpg");
}
.bsfi {
  background-image: url("https://keenitsolutions.com/products/wordpress/braintech/wp-content/uploads/2020/11/pro_06-500x500.jpg");
}
.cominfo {
  background-image: url("http://www.canvendor.com/assets/images/home/industries/com&info.png");
}
.healthcar {
  background-image: url("http://www.canvendor.com/assets/images/home/industries/health.jpg");
}
.homeinduscon {
  margin-top: none !important;
  position: absolute;
  z-index: 2;
  /* padding-left: 20px; */
  /* padding-bottom: 50px; */
  padding-top: 18px;
  text-align: center;
  bottom: 15px;
  width: 75%;
  height: 80px;
  left: 60px;
  border-radius: 7px;
  box-shadow: 1px 0px 20px 0px rgba(139, 137, 137, 0.583);
  background-color: white;
  color: black;
}
.showcase .showcase-text {
  padding: 3rem;
}
.showcase .showcase-img {
  min-height: 30rem;
  background-size: cover;
}
@media (min-width: 768px) {
  .showcase .showcase-text {
    padding: 7rem;
  }
}

.section-title #lettalk::after {
  background: white !important;
}

.grey-section {
  /* background: #F7F7F7 ; */
}
.common-section {
  /* padding: 100px 0; */
  /* background: #FFF; */
}
/* .container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
} */

.sub-heading {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-size: 14px;
  /* font-style: italic; */
  color: #888;
  line-height: 21px;
  margin-top: 15px;
}

.text-left {
  text-align: left;
}
.head-cube {
  width: 30px;
  height: 30px;
  background: #094972;
}
.serv_item {
  /* background: #FFF;
   */
  color: white;
  background-size: cover;
  opacity: 2;
  padding: 20px;
  width: 19.7%;
  margin: 1px;
  height: 315px;
  float: left;
  display: inline-block;
  text-align: center;
  padding-top: 35px;
  overflow: hidden;
  -webkit-transition: all 600ms ease-in-out;
  -moz-transition: all 600ms ease-in-out;
  -o-transition: all 600ms ease-in-out;
  transition: all 600ms ease-in-out;
}

.serv_cont {
  margin-top: 32%;
  -webkit-transition: all 600ms ease-in-out;
  -moz-transition: all 600ms ease-in-out;
  -o-transition: all 600ms ease-in-out;
  transition: all 600ms ease-in-out;
}
.serv_item h3 {
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 2px;
  padding-bottom: 10px;
  position: relative;
  margin-bottom: 10px;
  font-weight: 400;
}
.serv_cont_desc {
  line-height: 40px;
  /* font-size: 0; */
  font-size: 14px;
  opacity: 0;
  color: #fff;
  padding-top: 10px;
  -webkit-transition: all 900ms ease-in-out;
  -moz-transition: all 900ms ease-in-out;
  -o-transition: all 900ms ease-in-out;
  transition: all 900ms ease-in-out;
}
.serv_cont a {
  color: #f99e2e;
  font-size: 14px;
  letter-spacing: 1px;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

.serv_item:hover {
  background: #001a57;
}
.serv_item:hover .iconser {
  color: white !important;
}
.serv_item:hover > .serv_cont {
  margin-top: 0%;
}
.serv_item:hover .serv_cont_desc {
  line-height: 20px;
  opacity: 1;
}
.serv_item:hover h3 {
  color: #fff;
}
.serv_item h3:after {
  border-bottom: 1px solid #f99e2e;
  bottom: 2px;
  content: "";
  height: 0;
  position: absolute;
  left: 40%;
  width: 20%;
}
.main-head {
  font-weight: bold;
}
.main-head :after {
  border-bottom: 1px solid #f99e2e;
  bottom: 2px;
  content: "";
  height: 0;
  position: absolute;
  left: 40%;
  width: 20%;
}
.common-section {
  /* padding: 100px 0; */
  background: #fff;
}
.fifty-block {
  width: 50%;
  float: left;
  padding-left: 7%;
  padding-right: 7%;
  padding-top: 87px;
  padding-bottom: 87px;
  background-color: #eff1f7;
  /* background: rgb(31,33,72);
  background: linear-gradient(27deg, rgba(31,33,72,1) 55%, rgba(24,63,113,1) 91%); */
  /* background: rgb(31,33,72);
  background: linear-gradient(27deg, rgba(31,33,72,1) 25%, rgba(49,50,157,1) 100%); */
  color: rgb(31, 33, 72);
}
.fifty-block.left {
  background: #ccc;

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow: hidden;
  min-height: 400px;
}

.mainloc {
  box-sizing: border-box;
  display: grid;
  /* place-items: center; */
  min-height: 100vh;
  background-color: #f2f1ef;
  /* background: var(--gradient-9); */
}

:root {
  --magnifier: 6;
  --gap: 1vmin;
  --transition: 0.5s;
}

.loca {
  width: 95vw;
  height: 80vmin;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  gap: var(--gap);
}
.loca h5 {
  text-align: center;
  color: white;
  padding-top: 60px;
}
.loca p {
  display: none;
  background-color: #040c1985;
  text-align: center;
  color: white;
  padding-top: 60px;
  padding-bottom: 30px;
  padding-left: 10px;
  padding-right: 10px;
}
.locimg {
  --brightness: 0.75;
  background-size: cover;
  --grayscale: 0.3;
  transition: flex var(--transition), filter var(--transition);
  height: 100%;
  width: 40% !important;
  filter: grayscale(var(--grayscale)) brightness(var(--brightness));
  object-fit: cover;
  overflow: hidden;
  flex: 1;
}
.locimg1 {
  background-image: linear-gradient(
      to bottom,
      rgba(245, 246, 252, 0.177),
      rgba(15, 15, 15, 0.708)
    ),
    url("../public/assets/images/home/locations/america.jpg");
}
.locimg2 {
  background-image: linear-gradient(
      to bottom,
      rgba(245, 246, 252, 0.177),
      rgba(15, 15, 15, 0.708)
    ),
    url("../public/assets/images/home/locations/india.jpg");
}
.locimg3 {
  background-image: linear-gradient(
      to bottom,
      rgba(245, 246, 252, 0.177),
      rgba(15, 15, 15, 0.708)
    ),
    url("../public/assets/images/home/locations/canada.jpg");
}
.locimg4 {
  background-image: linear-gradient(
      to bottom,
      rgba(245, 246, 252, 0.177),
      rgba(15, 15, 15, 0.708)
    ),
    url("../public/assets/images/home/locations/uk.jpg");
}
.locimg5 {
  background-image: linear-gradient(
      to bottom,
      rgba(245, 246, 252, 0.177),
      rgba(15, 15, 15, 0.708)
    ),
    url("../public/assets/images/home/locations/location-bg-1.png");
}
.locimg:hover {
  --brightness: 1.15;
  --grayscale: 0;
  flex: var(--magnifier);
}
.locimg:hover p {
  display: block;
}

.bigtext {
  font-size: 55px;
  font-family: "Poppins", sans-serif;
  font-weight: 900;
}
.fifty-block p {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 34px;
  margin-bottom: 25px;
}
.hollow_button.small {
  padding: 10px 15px;
  font-size: 14px;
}
.hollow_button:hover {
  color: #fff;
  background: #28282e;
}
.hollow_button {
  display: inline-block;
  padding: 15px 25px;
  border: solid 1px #28282e;
  color: #28282e;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  text-align: center;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

.insightnew {
  width: 280px;
  height: 200px;
  background-color: white;
  position: absolute;
  box-shadow: 1px 1px 8px 0px gray;
  z-index: 2;
  left: 60px;
  top: 130px;
  /* right: 0px; */
}
.insightnew p {
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: left;
  color: rgba(0, 0, 0, 0.776);
  font-size: 16px;
  font-family: "Lato", sans-serif;
}
.inimg {
  filter: drop-shadow(2px 2px 10px gray);
}
.fpcon {
  font-size: 20px;
  font-family: "Lato", sans-serif;
  font-weight: bold;
  padding-left: 10px;
  padding-right: 10px;
}

.tp-subtitle-before {
  color: #47b3e4;
  /* font-family: var(--tp-ff-body); */
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.5;
  position: relative;
  text-transform: none;
}
.tp-subtitle-before::after {
  background-color: #47b3e4;
  /* background-color: var(--tp-text-body); */
  content: "";
  display: inline-block;
  height: 2px;
  margin-right: 15px;
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
  width: 100px;
}
.tp-section-title {
  color: #000;
}
.tp-section-title-large {
  font-size: 53px;
  line-height: 1.2;
  color: black;
}
.tp-section-subtitle {
  display: inline-block;
}
.tp-section-wrapper {
  position: relative;
  z-index: 9;
}

.getintouch {
  /* background-color: #cbd6de; */
  background: transparent;
  border: none;
  color: inherit;
  outline: none;
}
.tp-theme-btn span {
  color: #171717;

  display: inline-block;
  font-size: 20px;
  margin-top: 10px;
  position: relative;
}
.tp-theme-btn {
  text-align: center;
  background-color: #cbd6de;
  /* border: 1px solid #171717; */
  border: 1px solid #1f2148;
  /* border: 1px solid var(--tp-common-black); */
  border-radius: 50%;
  height: 250px;
  justify-content: center;
  text-align: center;
  transition: all 0.3s ease-out 0s;
  width: 250px;
  z-index: 2;
}

.getintouch :hover {
  background-color: #1f2148;
  /* background-color: #171717; */
  color: white;
}
.getintouch :hover b {
  background: none;
  /* background-color: #171717; */
  /* background-color: #1F2148; */
  color: white;
}
.tp-theme-btn b {
  color: #171717;
  /* color: var(--tp-common-black); */
  /* color: var(); */
  display: block;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 34px;
  padding: 15px 0;
}
.section-padding {
  padding: 60px 0;
  background-color: #fff;
}
.abtcol {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.about-section .about-info .info > span {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-size: 1.2rem;
  font-weight: 500;
  color: #fff;
  text-transform: uppercase;
}
.about-section .about-info .info h3 {
  font-size: 30px;
  font-size: 2rem;
  color: #fff;
  line-height: 1.2em;
  margin: 0.57em 0 0;
  padding-right: 42px;
}
.about-section .about-text h2 {
  font-size: 30px;
  font-size: 2rem;
  font-weight: 400;
  color: #001a57;
  line-height: 1.27em;
  margin: 0 0 0.67em;
  font-family: "Poppins", sans-serif;
}
.tp-theme-btn span svg:first-child {
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  -webkit-transform: translateX(-20px) translateY(0);
  transform: translateX(-20px) translateY(0);
}
.theme-btn {
  font-family: "Poppins", sans-serif;
  background: rgb(0, 15, 49);
  background: linear-gradient(
    45deg,
    rgba(0, 15, 49, 1) 50%,
    rgba(0, 25, 83, 1) 84%,
    rgba(0, 25, 84, 1) 100%
  );
  font-size: 16px;
  font-size: 1.06667rem;
  font-weight: 500;
  margin-left: 468px;
  color: #fff;
  padding: 11px 30px;
  display: inline-block;
}
.theme-btn1 {
  font-family: "Poppins", sans-serif;
  background: rgb(0, 15, 49);
  background: linear-gradient(
    45deg,
    rgba(0, 15, 49, 1) 50%,
    rgba(0, 25, 83, 1) 84%,
    rgba(0, 25, 84, 1) 100%
  );
  font-size: 16px;
  font-size: 1.06667rem;
  font-weight: 500;

  color: #fff;
  padding: 11px 30px;
  display: inline-block;
}
.about-section .about-text p {
  margin-bottom: 2.5em;
  color: #7a8595;

  line-height: 1.8em;
}
.about-section .about-info .info {
  background: rgb(0, 15, 49);
  background: linear-gradient(
    45deg,
    rgba(0, 15, 49, 1) 50%,
    rgba(0, 25, 83, 1) 84%,
    rgba(0, 25, 84, 1) 100%
  );
  max-width: 265px;
  padding: 36px 40px;
  position: absolute;
  left: 0;
  top: 22px;
}

.grey-bg-4 {
  background-color: white;
  /* background-color: #cbd6decc; */
  /* background-color: var(--tp-grey-3); */
}
.pt-140 {
  padding-top: 140px;
  padding-bottom: 140px;
}

.tp-section-wrapper {
  position: relative;
  z-index: 9;
}
.tp-section-subtitle {
  display: inline-block;
  font-family: Poppins, sans-serif;

  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.18em;
}
.tp-section-title {
  font-size: 42px;
  font-weight: 700;
}
.tp-section-title1 {
  font-size: 36px;
}
.white-bg {
  background: #fff;
}
.pt-25 {
  padding-top: 25px;
}
.pb-45 {
  padding-bottom: 45px;
}
.pt-60 {
  padding-top: 60px;
}
.pb-60 {
  padding-bottom: 60px;
}
.pr-25 {
  padding-right: 25px;
  padding-left: 25px;
}
.tp-service__icon {
  /* padding: 10px 0px; */
  text-align: left;
  color: #171717;

  font-size: 52px;
}
.tp {
  transition: 0.6s ease-in-out;
}
.tp:hover .iconser {
  color: white !important;
}
.tp :hover {
  cursor: pointer;
  color: white;
  background: rgb(0, 15, 49);
  background: linear-gradient(
    45deg,
    rgba(0, 15, 49, 1) 50%,
    rgba(0, 25, 83, 1) 84%,
    rgba(0, 25, 84, 1) 100%
  );
}

.pb-25 {
  padding-bottom: 25px;
}
.tp-service {
  background-color: #d9e1e7;
  text-align: left;
  width: 350px;
  box-shadow: 0px 10px 20px rgba(4, 33, 63, 0.05);
}
.tp-service1 {
  width: 300px;
  box-shadow: 0px 10px 20px rgba(4, 33, 63, 0.05);
}
.tp-service2 {
  background-color: #f8fafa !important;
  box-shadow: 0px 10px 20px rgba(4, 33, 63, 0.05);
}
.tp-service2 p {
  font-size: 15px;
  margin-bottom: 25px;
  padding: 0 10px;
  color: #878787;
  line-height: 26px;
  text-align: left;
}
.tp-service1 p {
  font-size: 15px;
  margin-bottom: 25px;
  padding: 0 10px;
  color: #878787;
  line-height: 26px;
}
.tp-service p {
  text-align: left;
  font-size: 15px;
  margin-bottom: 25px;
  padding: 0 10px;
  color: #878787;
  line-height: 26px;
}
.tp-service__title {
  text-align: left;
  padding: 0 10px;
  font-size: 20px;
}
.tp-service__title1 h3 {
  text-align: left;
}
.tp-service__title h3 {
  line-height: 1.3;
}
.mb-70 {
  margin-bottom: 70px;
}
.mb-30 {
  margin-bottom: 30px;
}
.tp-btn-sm-black {
  text-align: left;
  padding: 0 10px;
  display: block;
  font-family: Poppins, sans-serif;
  color: #000;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.18em;
  line-height: 22px;
  text-transform: uppercase;
}
.tp-service__title a {
  background-image: linear-gradient(#000, #000), linear-gradient(#000, #000);
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  background-size: 0 1px, 0 1px;
  display: inline;
  transition: background-size 0.4s linear;
}

.pb-30 {
  padding-bottom: 30px;
}
.pt-30 {
  padding-top: 30px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.d-inline-block {
  display: inline-block !important;
}

.list-blog-title {
  color: #171717;

  font-family: Poppins, sans-serif;
  font-size: 24px;
  font-weight: 400;

  line-height: 1.3;
  margin-top: 0;
}
.pt-130 {
  padding-top: 130px;
}
.pl-30 {
  padding-left: 30px;
}
.pb-20 {
  padding-bottom: 20px;
}
.tp-blog {
  margin-bottom: 50px;
}
.tp-blog__thumb {
  overflow: hidden;
  position: relative;
}
.pb-15 {
  padding-bottom: 15px;
}
.pt-25 {
  padding-top: 25px;
}
.tp-blog__meta {
  color: #878787;

  text-transform: uppercase;
}
.tp-blog__meta a {
  background-image: linear-gradient(#000, #000), linear-gradient(#000, #000);
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  background-size: 0 1px, 0 1px;
  display: inline;
  font-family: Poppins, sans-serif;
  /* font-family: var(--tp-ff-heading); */
  font-size: 12px;
  letter-spacing: 0.18em;
  line-height: 22px;
  transition: background-size 0.4s linear;
}
.tp-large-title {
  font-size: 24px;
}
.tp-blog__title {
  color: #333;

  line-height: 1.5;
  margin-bottom: 20px;
  padding-right: 45px;
}
.tp-blog-list__item-thum {
  margin-right: 30px;
  overflow: hidden;
}
.pt-15 {
  padding-top: 15px;
}
.pb-10 {
  padding-bottom: 10px;
}



.position-relative {
  position: relative !important;
}
.tp-fe-bg {
  background-size: cover;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 70.5%;
  background-image: url("../public/assets/images/blog/blog-2.jpg");
}
.tp-modify-width {
  width: 29.5%;
  background-color: #1f2148;
}
.grey-bg-3 {
  /* background-color: #222;   */
  background-color: #1f2148;
}
.tp-section-subtitle-sm {
  color: #adadad;

  font-family: Poppins, sans-serif;

  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  padding-left: 30px;
  letter-spacing: 0.18em;
  text-transform: uppercase;
}
@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .tp-white-text {
    padding: 40px;
  }
}
.tp-white-text-sm {
  color: #fff;

  font-family: Poppins, sans-serif;

  font-size: 30px;
  padding: 20px 0;
  padding-left: 30px;
}
.tp-white-text p {
  color: #9f9f9f;
  padding-left: 20px;

  padding-right: 20px;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.6;
  text-transform: none;
  padding-left: 30px;
}

.colsty {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.react-multi-carousel-list {
  padding-left: 38px !important;
}

/* about */
.page-banner {
  z-index: 1;
  position: relative;
  background-color: #0e1e36;
  text-align: center;
}
.page-bannernew {
  width: 100%;
  height: 380px;
  background-image: url("/public/service-inner-page-bg.jpg");
  background-size: cover;
}
.SingleService {
  padding-top: 120px;
}
.tp-section-wrapper p {
  font-size: 18px;
  line-height: 1.34;
  padding-top: 30px;
}
.heroPictureCardBox {
  color: #fff;
  background-image: linear-gradient(239deg, #007bbd, #003f61);
  opacity: 0.9;
  border-radius: 4px;
  box-shadow: 0 25px 100px 0 rgba(0, 0, 0, 0.15);

  display: flex;

  flex-direction: column;

  justify-content: center;

  align-items: flex-start;
  min-height: calc((80 / 7) * 1rem);
  /* min-height: calc((280/16)*1rem); */
  margin-top: calc(-160px + 3rem);
  width: auto !important;
  padding: 2rem;
}
.page-banner__content {
  position: relative;
}

.mb-20 {
  margin-bottom: 20px;
}

.page-banner__content .transparent-text {
  left: 0;
  top: -34px;
  z-index: -1;
  opacity: 0.3;
  font-size: 128px;
  font-weight: 700;
  line-height: 100px;
  position: absolute;
  white-space: nowrap;
  -webkit-text-stroke: transparent;
  -webkit-text-stroke-width: 1.5px;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-color: rgba(255, 255, 255, 0.3);
}

.page-banner__content h1 {
  color: #fff;
  font-weight: 700;
  font-size: 64px;
  line-height: 78px;
}
.page-banner__content h1 span {
  font-weight: 300;
}
.page-banner__media {
  max-height: 450px;
  position: relative;
  margin-right: calc(-50vw + 50%) !important;
}
.page-banner__media:after {
  top: 0;
  width: 100%;
  left: -1px;
  content: "";
  z-index: 1;
  height: 100%;
  position: absolute;
  background-size: cover;
  background-image: url("/public/page-banner-overly.png");
}

@media (max-width: 575px) {
  .theme-btn {
    margin-left: 0px !important;
  }
  .pt-xs-60 {
    padding-top: 60px;
  }
}
.pt-120 {
  padding-top: 120px;
}
.pb-100 {
  padding-bottom: 100px;
}
.company-skill {
  position: relative;
  z-index: -1;
}
.mb-20 {
  margin-bottom: 20px;
}
.fw-500 {
  font-weight: 500;
  color: #0e1e36;
}

.mb-25 {
  margin-bottom: 25px;
}
.color-pd_black {
  color: #04213f;
  font-size: 48px;
  line-height: 60px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.company-skill__content .title:not(h6) span {
  font-weight: 300;
}
.font-la {
  font-family: "Lato", sans-serif;
}

.company-skill__media-wrapper {
  position: relative;
}
.company-skill .container:after {
  top: -305px;
  content: "";
  z-index: -1;
  left: -414px;
  height: 1117px;
  width: 2552.29px;
  position: absolute;
  background-size: auto;
  background-repeat: no-repeat;
  /* -webkit-animation: scale 3s linear infinite; */
  animation: scale 3s linear infinite;
  background-image: url("/public/company-skill-bg.png");
}

/* contact new */
.pb-120 {
  padding-bottom: 120px;
}

.pt-120 {
  padding-top: 120px;
}
.contact-us__content {
  max-width: 495px;
}
.color-d_black {
  color: #0e1e36;
  font-size: 48px;
  line-height: 60px;
  font-weight: 600;
}
@media (min-width: 992px) {
  .carrer .our-storycar {
    width: 90% !important;
  }
  .our-storycar p {
    width: 300px !important;
  }
  .contact-us__item-wrapper {
    margin-left: -11px;
  }
}
.contact-us__item-wrapper {
  margin-bottom: -40px;
}
.contact-us__item {
  /* max-width: 254px; */
}
.contact-us__item-body {
  padding-left: 20px;
}
.mb-40 {
  margin-bottom: 40px;
}
.contactloc {
  font-size: 20px;
  line-height: 26px;
}
.fal {
  color: #4069ff;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}
.contact-us__item .icon {
  font-size: 28px;
}
.fa-map-marker-alt:before {
  content: "\f3c5";
}
.mb-120 {
  margin-bottom: 120px;
}
.contact-us hr {
  opacity: 1;
  border-radius: 5px;
  border: 1px solid #e2e9ff !important;
}
@media (min-width: 768px) {
  .contact-form #contact-map {
    height: 100%;
    position: absolute;
  }
}
.contact-form #contact-map {
  width: 100%;
  height: 300px;
}
.tp-ct-form {
  border: 1px solid #f1f1f1;
}
.pr-110 {
  padding-right: 110px;
}
.pl-110 {
  padding-left: 110px;
}
.pb-130 {
  padding-bottom: 130px;
}
.pt-80 {
  padding-top: 80px;
}
.white-bg {
  background: #fff;
}
.tp-ct-form input {
  background-color: #f5f5f3;
  outline: none;
  border: 0;
  float: left;
  height: 60px;
  margin-bottom: 20px;
  margin-right: 30px;
  padding-left: 20px;
  padding-right: 30px;
  width: 100%;
}
.tp-ct-form textarea {
  background-color: #f5f5f3;

  border: 0;
  height: 209px;
  margin-bottom: 20px;
  padding-left: 20px;
  padding-right: 30px;
  padding-top: 20px;
  resize: none;
  width: 100%;
}
.tp-btn-border {
  border: 1px solid hsla(0, 0%, 53%, 0.25);
  /* color: #171717; */
  background-color: rgb(31, 33, 72);
  color: white;
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.18em;
  line-height: 22px;
  padding: 20px 40px;
  text-transform: uppercase;
}
.tp-btn-border:hover {
  background-color: #040c19;
  color: white;
}
.mt-60 {
  margin-top: 60px;
}
/* services */
.digital-area .mb-minus-30 {
  margin-bottom: -30px;
}
.digital-area .why-choose__item {
  background: #fff;
  margin-bottom: 30px;
  padding: 40px 30px 30px;
  height: calc(100% - 30px);

  box-shadow: 0px 10px 20px rgba(4, 33, 63, 0.05);
}
.mb-15 {
  margin-bottom: 15px;
}
.color-d_black {
  color: #0e1e36;
  font-size: 20px;
  line-height: 26px;
  margin: 0px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  padding: 0;
  border: 0;
  outline: 0;
}
.why-choose__item .description {
  max-width: 262px;
}
.our-company .container:after {
  top: -587px;
  content: "";
  left: -564px;
  z-index: -1;
  width: 1300px;
  height: 1285.06px;
  position: absolute;
  background-size: auto;
  background-repeat: no-repeat;

  animation: scale 3s linear infinite alternate-reverse;
  background-image: url("/public/why-counter-overly-3.png");
}

.our-company .container {
  position: relative;
}
@keyframes scale {
  0% {
    transform: scaleY(1);
  }
  100% {
    transform: scaleY(1.5);
  }
}
.pb-140 {
  padding-bottom: 140px;
}
@media (min-width: 1200px) {
  .why-choose__media-wrapper {
    margin-right: -30px;
  }
  .why-choose__media-wrapper {
    position: relative;
  }
}
.gallery-bar {
  top: 10px;
  width: 30px;
  left: -15px;
  height: 300px;
  position: absolute;
  background: #4069ff;

  animation: move-bottom 3s linear infinite;
}
.why-choose__media {
  max-width: 580px;
}
@media (min-width: 1200px) {
  .why-choose__content:not(.why-choose__content-home) {
    padding-left: 85px;
  }
}
.why-choose__item-wrapper {
  gap: 50px 30px;

  grid-template-columns: minmax(210px, 262px) minmax(210px, 262px);
}
.pb-110 {
  padding-bottom: 110px;
}
.why-choose-cta-banner .cta-banner {
  position: relative;
  z-index: 2;
  margin-bottom: 0;
}
.cta-banner__content {
  gap: 30px;
  z-index: 1;
  padding-top: 52px;
  position: relative;
  padding-bottom: 52px;

  box-shadow: 0px 10px 20px rgba(4, 33, 63, 0.05);
  background-color: #c8d1d9;
  /* background-color: #4069FF; */
}
.pr-85 {
  padding-right: 85px;
}
.pl-85 {
  padding-left: 85px;
}
.cta-banner__content-text {
  max-width: 558px;
}
.color-white {
  color: #000f31;
}
.pb-140 {
  padding-bottom: 140px;
}
.pt-90 {
  padding-top: 90px;
}
.tp-theme-btn p {
  color: #adadad;

  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.18em;
  text-transform: uppercase;
}
.tp-btn-inner:hover {
  background: none;
}
.tp-btn-inner:hover .pointerimg1 {
  display: block !important;
}
.tp-btn-inner:hover .pointerimg {
  display: none;
}
.custom-left-arrow,
.custom-right-arrow {
  background-color: #c91919;
  border: none;
  cursor: pointer;
  font-size: 24px;
  outline: none;
}

.tp-btn-sm-black span {
  cursor: pointer;
  color: #171717;
  color: var(--tp-common-black);
  display: inline-block;
  font-size: 13px;
  line-height: 27px;
  margin-right: 15px;
  position: relative;
  text-align: center;
}

.page-title-content h2 {
  margin-bottom: 0;
  color: #ffffff;
  font-size: 50px;
}
.mb-120 {
  margin-bottom: 120px;
}

/* industriesnew */

.disable-arrow {
  background-color: rgba(0, 0, 0, 0) !important;
}

.disable-arrow:hover {
  background-color: rgba(0, 0, 0, 0) !important;
}

.disable-arrow::before {
  content: none !important;
}
.react-multiple-carousel__arrow--left {
  left: -33px !important;
}
.react-multiple-carousel__arrow--right {
  right: -33px !important;
}

.tab {
  padding-top: 20px;
  padding-bottom: 3rem;
  background: linear-gradient(
    to right,
    #2b0a3d 0%,
    #2b0a3d 50%,
    #fff calc(50% + 1px),
    #fff 100%
  );
  box-shadow: 0 0 80px 0 rgba(43, 10, 61, 0.25);
}
.tab p {
  padding: 30px;
  color: white;
  font-size: 15px;
  line-height: 1.8em;
}
@media (min-width: 992px) {
  .article-quote-text h2 {
    font-size: 2.25rem;
    font-size: 2.25rem;
    color: #15011d;
    letter-spacing: 0.12px;
    line-height: 48px;

    font-weight: 400;
    text-align: left;
    margin-bottom: 16px;
  }
}

.privacy-policy-container h2 {
  font-size: 28px;
  margin-bottom: 8px;
  margin-top: 32px;
  font-weight: 600;
  color: #007bbd;
}
.privacy-policy-container h4 {
  font-size: 18px;
  margin-bottom: 8px;
  margin-top: 16px;
  font-weight: 600;
  color: black;
}
.privacy-policy-container p {
  font-size: 15px;
}
