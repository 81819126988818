.location-section-container {
  padding-top: 70px;
  padding-bottom: 70px;
  position: relative;
}
.location-section-container::after {
  top: -487px;
  content: "";
  left: -564px;
  z-index: -1;
  width: 1300px;
  height: 1285.06px;
  position: absolute;
  background-size: auto;
  background-repeat: no-repeat;

  animation: scale 3s linear infinite alternate-reverse;
  background-image: url("/public/why-counter-overly-3.png");
}
@media (width<992px) {
  .location-section-container::after {
    display: none;
  }
}
.location-section-title {
  text-align: center;
  color: #0e121d;
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 70px;
}
.location-item-col {
  padding: 4px;
}
.location-item {
  position: relative;
  transition: ease-in-out 1.3s;
  overflow: hidden;
}
.location-item::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(217, 225, 231, 0) 100%
  );
  transition: ease-in-out 0.8s;
}
.location-item img {
  transition: ease-in-out 1s;
}
.location-item:hover img {
  transform: scale(1.2);
}
.location-item:hover:after {
  background: rgb(0, 15, 49);
  background: linear-gradient(
    0deg,
    rgba(0, 15, 49, 1) 0%,
    rgba(0, 15, 49, 0.29744397759103647) 100%
  );
}
.location-item img {
  width: 100%;
  height: auto;
}
.home-location-content {
  position: absolute;
  bottom: -75%;
  z-index: 2;
  text-align: center;
  width: 90%;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
  transition: ease-in-out 1.3s;
  pointer-events: none;
  padding: 20px 0px;
}
.location-continent {
  font-size: 20px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-transform: uppercase;
  margin-bottom: 16px;
}
.location-item:hover .home-location-content {
  bottom: 0%;
  pointer-events: initial;
  overflow-y: auto;
  scrollbar-width: none;
}
.location-item:hover .location-content-center {
  bottom: -20%;
}

.location-continent::after {
  content: "";
  width: 40px;
  height: 1px;
  background-color: white;
  margin-top: 4px;
}
.location-name {
  font-size: 18px;
  color: white;
  opacity: 0;
  transition: ease-in-out 0.8s;
  text-transform: uppercase;
}
.location-item:hover .location-name {
  opacity: 1;
}
.location-address {
  color: white;
  font-size: 14px;
  line-height: 5;
  transition: ease-in-out 0.8s;
  opacity: 0;
}

.location-item:hover .location-address {
  line-height: 1.5;
  opacity: 1;
}
