.showcase .showcase-text {
    padding: 3rem;
  }
  .showcase .showcase-img {
    min-height: 30rem;
    background-size: cover;
    background-image: url('http://www.canvendor.com/assets/images/services/robotic-process-automation.jpg');
  }
  @media (min-width: 768px) {
    .showcase .showcase-text {
      padding: 7rem;
    }
  }
  
  .automotive{
    width: 100%;
    height: 90vh;
    background-image: url('./autobanner.jpg');
   background-attachment: fixed;
   background-position: center; 
  }
  .automotive-title{
    background-color:#1f2148 ;
  /* position: sticky;
  top:80px; */
    color: white;
    height: 60px;
    padding: 40px;
  }
  .automotive-title h2{
    padding-left: 20px;
    padding-top: 10px;
  }
  .automotive-content{
    position: absolute;
    width: 60%;
    height: 200px;
    background-color:#1f2148e2;
    margin-left: 40px;
    margin-top: 200px;
    position: absolute;
    z-index: -1;
  border-left: 5px solid yellow;
   box-shadow: 10px 5px 20px 10px rgba(171, 170, 170, 0.718);
  }
  
  .automotive-content p{
    padding: 20px;
    font-size: 20px;

    line-height: 2em;
    font-family:sans-serif;

  }
  .automotive-next{
    width: 100%;
    height: 80vh;
    background-image: url('./automotiviebanner.png');
   background-attachment: fixed;
   background-size: cover;
   background-position: center; 
   background-repeat: no-repeat;
  }
  .accordion-button:not(.collapsed){
    background-color: #1f2148 !important;
    color:white !important;
  }
  #jigs{
    background-color: #102871 !important;
    color: white !important;
  }
  #sup{
    background-color: #1f509b !important;
    color: white !important;
  }
  #chain{
background-color: #2f78c4 !important;
color: white !important;
  }
  .autoContent-Text{
    width: 70%;
    background-color:#1f2148  ;
    padding: 20px;
    border-left: 3px solid yellow;
  }
  .automotive-sec{
    width: 100%;
    height: 80vh;
    background-image: linear-gradient(to bottom, rgba(245, 246, 252, 0.605), rgba(177, 181, 187, 0.474)), url('./engine-g0999a85f8_1920.jpg');
   background-attachment: fixed;
   background-size: cover;
   background-position: center; 
   background-repeat: no-repeat;
  }
  /* banking */
  .banking{
    width: 100%;
    height: 90vh;
    background-image: url('./buss.jpg');
   background-attachment: fixed;
   background-position: center; 
   background-size: cover;
  }
  .banking-next{
    width: 100%;
    height: 100vh;
    background-image: url('./close-up-hand-holding-smartphone.jpg');
   background-attachment: fixed;
   background-size: cover;
   background-position: center; 
   background-repeat: no-repeat;
  }

  .banking-next-content{

    width: 60%;
    height: 400px;
    background-color:#0b2031cd;
    margin-left: 40px;
padding: 20px;
border-left: 3px solid yellow;
  }
  .bankContent-Text{
    font-size: 25px;
 padding-top: 80px;
 text-align: justify;
 /* font-family: sans-serif; */
 font-weight:100;
  }
  .banking-sec{
    width: 100%;
    height: 100vh;
    background-image: url('./4025865_17454.jpg');
   background-attachment: fixed;
   background-size: cover;
   background-position: center; 
   background-repeat: no-repeat;
  }
  
.page-title-area.item-bg-2 {
  background-image: url('./Manufacturing.jpg');
}
.page-title-area.item-bg-3 {
  background-image: url('./automotiviebanner.png');
}
.page-title-area.item-bg-4 {
  background-image: url('./buss.jpg');
}
.page-title-area.item-bg-5 {
  background-image: url('./Communication.jpg');
}
.page-title-area.item-bg-6 {
  background-image: url('./banner1.jpg');
}
.page-title-area {
  position: relative;
  z-index: 1;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 500px;
  background-attachment: fixed;
}
.page-title-area::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background: #000000a4;
  opacity: .77;
}
.d-table {
  width: 100%;
  height: 100%;
}
.d-table {
  display: table!important;
}
.d-table-cell {
  vertical-align: middle;
}
.d-table-cell {
  display: table-cell!important;
}
.page-title-content p {
  font-size: 20px;
  font-weight: 500;
  color: #ffffff;
  margin-bottom: 0;
}
.page-title-content p a {
  color: #ffffff;
  border-bottom: 1px solid #ffffff;
}
.page-title-content ul {
  padding-left: 0;
  list-style-type: none;
  background-color: #ffffff;
  -webkit-box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
  display: inline-block;
  padding: 8px 20px;
  border-radius: 30px;
  position: absolute;
  bottom: -16px;
  left: 20%;
  margin-bottom: 0;
}
.page-title-content ul li {
  color: #0d1820;
  display: inline-block;
  position: relative;
  font-weight: 400;
  font-size: 15px;
}
  .manufacturing{
    width: 100%;
    height: 100vh;
    background-image: url('./Manufacturing.jpg');
   background-attachment: fixed;
   background-size: contain;
  }
  .manufacturing-title{
    background-color:#1f2148 ;
    /* position: sticky;
    top:80px; */
      color: white;
      height: 60px;
      padding: 40px;
      margin-top: 90px;
  }
  .manufacturing-title h2{
    padding-left: 20px;
    padding-top: 10px;
  }
  .manufacturing-content{
    box-sizing: border-box;
    /* background-color: #e2e7ea; */
    padding: 20px;
  }
  .manufacturing-content p{
    margin-bottom: 15px;
    margin-top: 20px;
    padding-top: 100px;
    line-height: 1.8;
    color: #777777;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
    font-size: 15px;

  }
  .manufacturing-content h3{
    color: #2d3357;
    font-weight: 700;
    
  }
  .manufacturing-content ul li{
    color: rgb(33, 33, 33);
  }
  hr{
    color: yellow;
  }


  /* healthcare */
  .healthcare{
    width: 100%;
    height: 90vh;
    background-image: url('./banner1.jpg');
   background-attachment: fixed;
   background-position: center;  
   background-size: cover;
  }
  .CreativeAgencies {
    width: 100%;
    height: 90vh;
    background-image: url('./Communication.jpg');
   background-attachment: fixed;
   background-position: center;  
   background-size: cover;
  }